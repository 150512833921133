// import React from 'react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import starightcut1 from "../assets/img/update_2/normal/starightcut1.jpg";
import friescoated from "../assets/img/update_2/normal/friescoated.jpg";
import friesshoestring from "../assets/img/update_2/normal/friesshoestring.jpg";
import potatoban1 from "../assets/img/potatoban1.png";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './footer';


const Frozenpotatoes = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff',
                                border: '0px solid #333',
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    return (
        <div>
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${breadcumb_bg_2})` }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Products</h1>
                        <ul class="breadcumb-menu">
                            <li><Link to="/Idgnew/home">Home</Link></li>
                            <li>Products</li>
                        </ul>
                    </div>
                </div>
            </div> */}

            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">

                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white' }}>PRODUCTS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica' ,color:'#F0B323'}}>Frozen Potatoes</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <img
                            width="2000"
                            height=""
                            src={potatoban1}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                                height: "",
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Straight cut French fries 6-11mm
            </h2>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${starightcut1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>

                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>

                                {/* <div className="row">
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">More strips per kg, prompting more servable portions</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Incredible fresh-looking surface</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Uniform brilliant shade</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>More strips per kg. prompting more servable portions</li>
                                <li>Incredible fresh-looking surface</li>
                                <li>Uniform brilliant shade</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <h4 className="sec-title">Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                Deep Fry/Shallow Fry: Preheat oil to 175°C. Cook half basket of French Fries for about 3 minutes until light golden colour

                                </li>
                                <li>
                                Air Fry: Preheat the air fryer to 200°C. Spread your  French Fries in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between

                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 months</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Ingredients</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Potatoes, Refined Vegetable Oil</li>
                            </ul>
                            {/* <table className="table table-bordered text-center" style={{ boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#f8f9fa' }}>Keep frozen at -18°C or below</td>
                                        <td style={{ backgroundColor: '#e9f7ef' }}>Do not refreeze after thawing</td>
                                        <td style={{ backgroundColor: '#f9ebd4' }}>Shelf life 18 months</td>
                                    </tr>
                                </tbody>
                            </table> */}

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Coated Fries (6-11mm)
            </h2>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>
                                {/* <div className="row">
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">More strips per kg, prompting more servable portions</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Incredible fresh-looking surface</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Uniform brilliant shade</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>More strips per kg. prompting more servable portions</li>
                                <li>Great crispy texture</li>
                                <li>Uniform golden colour</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                Deep Fry/Shallow Fry: Preheat oil to 175°C. Cook half basket of French Fries for about 3 minutes until light golden colour
                                </li>
                                <li>
                                Air Fry: Preheat the air fryer to 200°C. Spread your  French Fries in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between

                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 months</li>
                            </ul>
                            {/* <table className="table table-bordered text-center" style={{ boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#f8f9fa' }}>Keep frozen at -18°C or below</td>
                                        <td style={{ backgroundColor: '#e9f7ef' }}>Do not refreeze after thawing</td>
                                        <td style={{ backgroundColor: '#f9ebd4' }}>Shelf life 18 months</td>
                                    </tr>
                                </tbody>
                            </table> */}

                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-0 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${friescoated})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'
                                }}
                            ></div>
                        </div>


                    </div>
                </div>
            </div>

            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Shoestring fries
            </h2>

            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-0 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${friesshoestring})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'
                                }}
                            ></div>
                        </div>

                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>


                                {/* <div className="row">
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">More strips per kg, prompting more servable portions</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Incredible fresh-looking surface</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 d-flex">
                                        <div className="card flex-fill custom-card">
                                            <div className="card-body">
                                                <p className="card-text">Uniform brilliant shade</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>More strips per kg, prompting more servable portions</li>
                                <li>Incredible fresh-looking surface</li>
                                <li>Uniform brilliant shade</li>
                            </ul>



                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                    Deep Fry/Shallow Fry: Preheat oil to 175°C. Cook half basket of  French Fries for about 3 minutes until light golden colour
                                </li>
                                <li>
                                    Air Fry: Preheat the air fryer to 200°C. Spread your  French Fries in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between
                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 18 months</li>
                            </ul>
                            {/* <table className="table table-bordered text-center" style={{ boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#f8f9fa' }}>Keep frozen at -18°C or below</td>
                                        <td style={{ backgroundColor: '#e9f7ef' }}>Do not refreeze after thawing</td>
                                        <td style={{ backgroundColor: '#f9ebd4' }}>Shelf life 18 months</td>
                                    </tr>
                                </tbody>
                            </table> */}

                        </div>
                    </div>
                </div>
            </div>
            <br /> <br /> <br /> <br />
            <Footer />
            </div>
            );
};

export default Frozenpotatoes;
