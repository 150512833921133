// import React from 'react';
import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';

import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quality from './quality';
import send_email from './send_email.php'




const Seniorsalesexecutive = () => {

    const formRef = useRef();

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData(formRef.current);

    //     try {
    //         const response = await fetch('/send_email.php', {
    //             method: 'POST',
    //             body: formData
    //         });
    //         console.log(formData);

    //         if (response.ok) {
    //             toast.success('Email sent successfully.');
    //         } else {
    //             toast.error('Error: Email not sent.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         toast.error('Error: Email not sent.');
    //     }

    //     // formRef.current.reset();
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //  const formData = new FormData(formRef.current);

        //  formData.append('full_name', formRef.current.elements.full_name.value);
        //  formData.append('email', formRef.current.elements.email.value);
        //  formData.append('phone', formRef.current.elements.phone.value);
        //  formData.append('cover_letter', formRef.current.elements.cover_letter.value);
        //  console.log(formData);
        const formData = new FormData();
        formData.append('full_name', 'John Doe');
        formData.append('email', 'john.doe@example.com');
        formData.append('phone', '123-456-7890');
        formData.append('cover_letter', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.');

        console.log(formData);


        try {
            const response = await fetch('/send_email.php', {
                method: 'POST',
                body: formData
            });
            console.log("response",response);

            if (response.ok) {
                toast.success('Email sent successfully.');
            } else {
                toast.error('Error: Email not sent.');
            }
           
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error: Email not sent.');
        }

        formRef.current.reset();
    };

    return (
        <div>
             <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:"white"}}>CAREERS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Careers</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                               
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-area mb-30 text-center text-xl-start">
                            <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Key responsibility(Senior-Sales-Executive)</h4>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>As Sales Person, you are responsible for customer service and acquiring new customers. You will also continue to expand sales and revenue in the region.</li>
                            <li>You are responsible for acquiring new customers from end users and wholesalers.</li>
                            <li>You will be responsible for supporting and expanding the sales area in accordance with the sales strategy.</li>
                            <li>You are also responsible for implementing central agreements with individual customers in the assigned region. You work with regional wholesalers in coordination with the regional sales manager and colleagues.</li>
                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title">Qualification :</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Minimum Graduate or Equivalent to graduation</li>
                            <li>MBA is preferred but not mandatory</li>
                            <li>Experience in the frozen quick food is compulsory</li>
                        </ul>

                        <p className="sec-title" style={{ fontFamily:'helvetica' }}>You have excellent knowledge of the food service business. You have training in the catering or food sector with good commercial or business knowledge.<br /> You have experience in the catering industry, in food service field sales or in wholesale (food sector).<br /> You are self-organized, strong in setting priorities and take your own initiative.<br /> You are sociable, have good English skills and a strong entrepreneurial spirit.</p>
                    </div>



                    <h2 className="mb-4 text-center" style={{ fontFamily:'helvetica' }}>Apply for this position</h2>
                    {/* ref={formRef} onSubmit={handleSubmit} */}
                    <form className="row g-3 border p-4" ref={formRef} onSubmit={handleSubmit}>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="full_name" className="form-label" style={{ fontFamily:'helvetica' }}>Full Name *</label>
                                <input type="text" className="form-control" style={{ fontFamily:'helvetica' }} id="full_name" name="full_name" placeholder="John Doe" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label" style={{ fontFamily:'helvetica' }}>Email *</label>
                                <input type="email" className="form-control" style={{ fontFamily:'helvetica' }}id="email" name="email" placeholder="john.doe@example.com" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label" style={{ fontFamily:'helvetica' }}>Phone *</label>
                                <input type="tel" className="form-control" style={{ fontFamily:'helvetica' }} id="phone" name="phone" placeholder="123-456-7890" required />
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                                    <label htmlFor="resume" className="form-label" style={{ fontFamily:'helvetica' }}>Upload CV/Resume *</label>
                                    <input type="file" className="form-control" style={{ fontFamily:'helvetica' }} id="resume" accept=".pdf,.doc,.docx" required />
                                </div>
                            <div className="mb-3">
                                <label htmlFor="cover_letter" className="form-label" style={{ fontFamily:'helvetica' }}>Cover Letter *</label>
                                <textarea className="form-control"  style={{ fontFamily:'helvetica' }} id="cover_letter" name="cover_letter" rows="4" placeholder="Write your cover letter here." required></textarea>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="agree" required style={{ fontFamily:'helvetica' }}/>
                                <label className="form-check-label" style={{ fontFamily:'helvetica' }} htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:'#F0B323' }}>Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                            </div>
                        </div>
                    </form>

                    <ToastContainer position="top-center" />




                </div>
            </div>
            <br />  <br />  <br />  <br />
            <Footer />
        </div>
    );
};

export default Seniorsalesexecutive;
