// import React from 'react';
import React, { useState, useEffect } from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';




const Careers = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    const handleClick = () => {
        window.location.href = '/about';
        window.scrollTo(0, 0);
    };
    const handleClickTwo = () => {
        window.location.href = '/home';
        window.scrollTo(0, 0);
    };
    return (
        <div>
             <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white' }}>CAREERS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Careers</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                               
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
           
            <br /><br />
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="input-group">
                            <select class="form-select" style={{ fontFamily:'helvetica' }}>
                                <option selected>All Job Category</option>
                                <option value="1">Human Resource</option>
                                <option value="2">Sales</option>
                            </select>
                            </div>
                    </div>
                    <div class="col">
                        <div class="input-group">
                            <select class="form-select" style={{ fontFamily:'helvetica' }}>
                                <option selected>All Job Type</option>
                                <option value="1">Full time</option>
                                </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-group">
                            <select class="form-select" style={{ fontFamily:'helvetica' }}>
                                <option selected>All Job Location</option>
                                <option value="1">Delhi</option>
                                <option value="2">India</option>
                                <option value="3"></option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt-4">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-body">
                                <h5 class="card-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Senior Sales Executive</h5>
                                <p className="card-text">
                                    <div className="awsm-job-more-container">
                                        <Link className="awsm-job-more" to={`/jobs/senior-sales-executive`} style={{ fontFamily:'helvetica',color:'#F0B323' }}>
                                            More Details
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                                />
                                            </svg>
                                            <span></span>
                                        </Link>
                                    </div>
                                </p>
                                {/* <a href="#" class="btn btn-primary">Apply</a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-body">
                                <h5 class="card-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Area Sales Manager</h5>
                                <p className="card-text">
                                    <div className="awsm-job-more-container">
                                        <Link className="awsm-job-more" to={`/jobs/area-sales-manager`} style={{ fontFamily:'helvetica',color:'#F0B323' }}>
                                            More Details
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                                />
                                            </svg>
                                            <span></span>
                                        </Link>
                                    </div>
                                </p>
                                {/* <a href="#" class="btn btn-primary">Apply</a> */}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-body">
                                <h5 class="card-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Senior Human Resource Manager</h5>
                                <p className="card-text">
                                    <div className="awsm-job-more-container">
                                        <Link className="awsm-job-more" to={`/jobs/senior-human-resource-manager`} style={{ fontFamily:'helvetica',color:'#F0B323' }}>
                                            More Details
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                                />
                                            </svg>
                                            <span></span>
                                        </Link>
                                    </div>
                                </p>

                                {/* <a href="#" class="btn btn-primary">Apply</a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-body">
                                <h5 class="card-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Sales Executive</h5>
                                <p className="card-text">
                                    <div className="awsm-job-more-container">
                                        <Link className="awsm-job-more" to={`/jobs/sales-executive`} style={{ fontFamily:'helvetica',color:'#F0B323'}}>
                                            More Details
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                                />
                                            </svg>
                                            <span></span>
                                        </Link>
                                    </div>
                                </p>
                                {/* <a href="#" class="btn btn-primary">Apply</a> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className="position-relative">
                <div className="position-absolute top-0 start-0 translate-middle p-3 careerText">
                    <span style={{ color: 'white', fontSize: '48px' }}>Careers</span>
                    <br />
                    <br />
                  
                    <Link to="/Idgnew/home" onClick={handleClickTwo} style={{ color: 'white', padding: 4 }}><span className="homeclass">Home</span> &nbsp;</Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> <span style={{ color: 'red' }}>Careers</span>
                </div>
                <img
                    width="2000"
                    height="2000"
                    src={carrierbanner}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'right',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                />
            </div> */}
            <br />  <br />  <br />  <br />
            <Footer/>
            </div >
            );
};

export default Careers;
