// import React from 'react';
import React, { useState, useEffect } from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import contact_feature_4 from "../assets/img/update_2/icon/contact_feature_4.svg";
import contact_feature_2 from "../assets/img/update_2/icon/contact_feature_2.svg";
import contact_feature_3 from "../assets/img/update_2/icon/contact_feature_3.svg";
import contact_feature_1 from "../assets/img/update_2/icon/contact_feature_1.svg";
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';


const Contactus = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);
    return (
        <div>
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>
                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:"white"}}>CONTACT US</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Contact Us</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height=""
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                                height: "",
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />
                        </div>
                        </div>
            </div>
            {/* <div class="space">
                <div class="container">
                    <div class="row gy-4">
                        <div class="col-xl-3 col-sm-6">
                            <div class="contact-feature">
                                <div class="contact-feature_icon">
                                    <img src={contact_feature_1} alt="Icon" />
                                </div>
                                <h4 class="box-title" style={{ fontFamily:'helvetica' }}>Factory Address</h4>
                                <span class="contact-feature_text heightExceed" style={{ fontFamily:'helvetica' }}> C-5-8, Maroli Udhyog Nagar, Nadod, <br /> Umbhrat Road,
                                    Navsari, Gujarat-396436</span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="contact-feature">
                                <div class="contact-feature_icon">
                                    <img src={contact_feature_4} alt="Icon" />
                                </div>
                                <h4 class="box-title" style={{ fontFamily:'helvetica' }}>Corporate Office</h4>
                                <span class="contact-feature_text heightExceed" style={{ fontFamily:'helvetica' }}>C-8/2 basement annd Ground floor<br/>Vasant Vihar near C block Community Center New Delhi - 110057 </span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="contact-feature">
                                <div class="contact-feature_icon">
                                    <img src={contact_feature_2} alt="Icon" />
                                </div>
                                <h4 class="box-title" style={{ fontFamily:'helvetica' }}>Email Address</h4>
                                <span class="contact-feature_text heightExceed2">
                                    <a href="mailto:support@icedreamglobal.com" style={{ fontFamily:'helvetica' }}>support@icedreamglobal.com</a>
                                </span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="contact-feature">
                                <div class="contact-feature_icon">
                                    <img src={contact_feature_3} alt="Icon" />
                                </div>
                                <h4 class="box-title" style={{ fontFamily:'helvetica' }}>Phone Number</h4>
                                <span class="contact-feature_text heightExceed2">
                                    <a href="tel:+9999954849" style={{ fontFamily:'helvetica' }}>(+91) 9873578910
                                    </a>

                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className="space mt-4 mb-4" style={{ padding: '2rem 0' }}>
  <div className="container">
    <div className="row gy-4" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <div className="col-xl-3 col-sm-6" style={{ display: 'flex', flexDirection: 'column', flex: '1 1 25%', minWidth: '200px' }}>
        <div className="contact-feature" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div className="contact-feature_icon">
            <img src={contact_feature_1} alt="Icon" />
          </div>
          <h4 className="box-title" style={{ fontFamily: 'helvetica',color:'#0f1138'}}>Factory Address</h4>
          <span className="contact-feature_text" style={{ fontFamily: 'helvetica' }}>
            C-5-8, Maroli Udhyog Nagar, Nadod, <br /> Umbhrat Road, Navsari, Gujarat-396436
          </span>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6" style={{ display: 'flex', flexDirection: 'column', flex: '1 1 25%', minWidth: '200px' }}>
        <div className="contact-feature" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div className="contact-feature_icon">
            <img src={contact_feature_4} alt="Icon" />
          </div>
          <h4 className="box-title" style={{ fontFamily: 'helvetica',color:'#0f1138' }}>Corporate Office</h4>
          <span className="contact-feature_text" style={{ fontFamily: 'helvetica' }}>
            C-8/2 basement and Ground floor<br />Vasant Vihar near C block Community Center New Delhi - 110057
          </span>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6" style={{ display: 'flex', flexDirection: 'column', flex: '1 1 25%', minWidth: '200px' }}>
        <div className="contact-feature" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div className="contact-feature_icon">
            <img src={contact_feature_2} alt="Icon" />
          </div>
          <h4 className="box-title" style={{ fontFamily: 'helvetica',color:'#0f1138'}}>Email Address</h4>
          <span className="contact-feature_text" style={{ fontFamily: 'helvetica' }}>
            <a href="mailto:support@icedreamglobal.com" style={{ fontFamily: 'helvetica' }}>support@icedreamglobal.com</a>
          </span>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6" style={{ display: 'flex', flexDirection: 'column', flex: '1 1 25%', minWidth: '200px' }}>
        <div className="contact-feature" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div className="contact-feature_icon">
            <img src={contact_feature_3} alt="Icon" />
          </div>
          <h4 className="box-title" style={{ fontFamily: 'helvetica',color:'#0f1138'}}>Phone Number</h4>
          <span className="contact-feature_text" style={{ fontFamily: 'helvetica' }}>
            <a href="tel:+9999954849" style={{ fontFamily: 'helvetica' }}>(+91) 9873578910</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


            <div class="" id="mapSec">
                <div class="contact-map2">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.0801193980747!2d72.85354431424533!3d21.02947999313118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0571ccb4b8867%3A0x7ec244669086e859!2sHN%20Indigos%20Private%20Limited!5e0!3m2!1sen!2sin!4v1626090743148!5m2!1sen!2sin" width="100%" height="200" style={{ border: '0' }} allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>

            <section class="space-bottom contact-form-sec">
                <div class="container">
                    <div class="reservation-area">
                        <h4 class="sec-title text-center mb-30" style={{ fontFamily:'helvetica',color:'#0f1138',textTransform: 'uppercase' }}>Send Us Message</h4>
                        <form action="mail.php" method="POST" class="booking-form3 ajax-contact" style={{ fontFamily:'helvetica' }}>
                            <div class="row">
                                <div class="form-group col-lg-4 col-md-6">
                                    <input type="text" class="form-control rounded-2" id="name" name="name"
                                        placeholder="Your Name" style={{ fontFamily:'helvetica' }}/>
                                    <i class="far fa-user" style={{color:'#F0B323'}}></i>
                                </div>
                                <div class="form-group col-lg-4 col-md-6">
                                    <input type="tel" class="form-control rounded-2" id="number" name="number"
                                        placeholder="Phone Number" style={{ fontFamily:'helvetica' }} />
                                    <i class="far fa-phone" style={{color:'#F0B323'}}></i>
                                </div>
                                <div class="form-group col-lg-4">
                                    <input type="text" class="form-control rounded-2" id="subject" name="subject"
                                        placeholder="Subject" style={{ fontFamily:'helvetica' }}/>
                                    <i class="far fa-tag" style={{color:'#F0B323'}}></i>
                                </div>
                                <div class="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" class="form-control rounded-2"
                                        placeholder="Write Message" style={{ fontFamily:'helvetica' }}></textarea>
                                    <i class="far fa-comment" style={{color:'#F0B323'}}></i>
                                </div>
                            </div>
                            {/* <div class="form-btn text-center">
                        <button class="th-btn rounded-2">Send Message<i
                                class="fa-solid fa-arrow-right ms-2"></i></button>
                    </div> */}
                            <div className="text-center">
                                <Link to="/" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:'#F0B323' }}>Send Message<i className="fa-solid fa-arrow-right ms-2"></i></Link>
                            </div>
                            <p class="form-messages mb-0 mt-3"></p>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
            </div>
            );
};

export default Contactus;
