import React from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';

const Seniorhrmanager = () => {
    const handleClick = () => {
        window.location.href = '/about';
        window.scrollTo(0, 0);
    };
    const handleClickTwo = () => {
        window.location.href = '/home';
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white' }}>CAREERS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323' }}>Careers</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                               
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-area mb-30 text-center text-xl-start">
                               <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Senior Human Resource Manager</h4>
                        
                            <p className="sec-title" style={{ fontFamily:'helvetica' }}>We are seeking an experienced and dynamic Senior HR Manager to lead our human resources initiatives and contribute to the success of our organization. The Senior HR Manager will play a pivotal role in aligning HR strategies with business goals, fostering a positive work culture, and driving talent management programs.</p>
                        </div>
                        <div className="title-area mb-30 text-center text-xl-start">
                            <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Responsibilities</h4>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Develop and implement HR strategies and initiatives aligned with the overall business strategy.</li>
                            <li>Lead and manage the HR team, providing guidance, mentorship, and support to ensure efficient and effective HR operations.</li>
                            <li>Oversee the end-to-end recruitment process, including sourcing, interviewing, and onboarding of new employees.</li>
                            <li>Design and implement performance management programs to enhance employee productivity and development.</li>
                            <li>Manage employee relations issues, including conflict resolution, disciplinary actions, and performance improvement plans.</li>
                            <li>Collaborate with department heads to identify and address training and development needs within the organization.</li>
                            <li>Ensure compliance with labor laws, regulations, and company policies, updating policies as necessary.</li>
                            <li>Manage compensation and benefits programs, ensuring competitive and equitable compensation structures.</li>
                            <li>Drive diversity, equity, and inclusion initiatives to foster a respectful and inclusive work environment.</li>
                            <li>Monitor HR metrics and analyze data to identify trends, provide insights, and make informed decisions.</li>
                            <li>Stay current with industry trends and best practices in HR, making recommendations for continuous improvement.</li>
                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start">
                            <p className="sec-title" style={{ fontFamily:'helvetica' }}>Qualification :</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Bachelor’s degree in Human Resources, Business Administration, or related field; Master’s degree preferred.</li>
                            <li>years of progressive HR experience, with a minimum of [Y] years in a leadership role.</li>
                            <li>Strong knowledge of labor laws, regulations, and HR best practices.
                                <br />
                                Excellent communication and interpersonal skills.
                                <br />
                                Proven track record of developing and implementing successful HR strategies and programs.
                                <br />
                                Exceptional leadership and team management abilities.
                                <br />
                                Ability to handle sensitive and confidential information with professionalism and discretion.
                            </li>
                            <li>Proficiency in HRIS (Human Resources Information System) and MS Office Suite.
                                HR certification (e.g., PHR, SPHR) is a plus.
                                <br /> <br />
                                <br />
                                <b>Job Category</b>: Human Resource<br />
                                <b> Job Type</b>: Full Time<br />
                                <b> Job Location</b>: Delhi,India
                            </li>
                        </ul>
                    </div>
                    {/* <div className="col-lg-6">
                        <form className="border p-4">
                            <h2 className="mb-4">Apply for this position</h2>
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Full Name *</label>
                                <input type="text" className="form-control" id="fullName" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email *</label>
                                <input type="email" className="form-control" id="email" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone *</label>
                                <input type="tel" className="form-control" id="phone" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="coverLetter" className="form-label">Cover Letter *</label>
                                <textarea className="form-control" id="coverLetter" rows="4" required></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="resume" className="form-label">Upload CV/Resume *</label>
                                <input type="file" className="form-control" id="resume" accept=".pdf,.doc,.docx" required />
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="agree" required />
                                <label className="form-check-label" htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="th-btn rounded-2">Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                            </div>
                        </form>
                    </div> */}
                     <div className="row">
                     <h2 className="mb-4 text-center" style={{ fontFamily:'helvetica' }}>Apply for this position</h2>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                <div className="mb-3">
                                    <label htmlFor="fullName" className="form-label" style={{ fontFamily:'helvetica' }}>Full Name *</label>
                                    <input type="text" className="form-control" style={{ fontFamily:'helvetica' }} id="fullName" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label" style={{ fontFamily:'helvetica' }}>Email *</label>
                                    <input type="email" className="form-control" style={{ fontFamily:'helvetica' }} id="email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label" style={{ fontFamily:'helvetica' }}>Phone *</label>
                                    <input type="tel" className="form-control" style={{ fontFamily:'helvetica' }} id="phone" required />
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                <div className="mb-3">
                                    <label htmlFor="coverLetter" className="form-label" style={{ fontFamily:'helvetica' }}>Cover Letter *</label>
                                    <textarea className="form-control" id="coverLetter" style={{ fontFamily:'helvetica' }} rows="4" required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="resume" className="form-label" style={{ fontFamily:'helvetica' }}>Upload CV/Resume *</label>
                                    <input type="file" className="form-control" style={{ fontFamily:'helvetica' }} id="resume" accept=".pdf,.doc,.docx" required />
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="agree" required style={{ fontFamily:'helvetica' }}/>
                                    <label className="form-check-label" style={{ fontFamily:'helvetica' }} htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:'#F0B323'}}>Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br />  <br />  <br />  <br />
            <Footer />
        </div>
    );
};

export default Seniorhrmanager;
