// import React from 'react';
import React, { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import blueyumwayseasmith from "../assets/img/blueyumwayseasmith.png";
import aboutbannerx10 from "../assets/img/aboutbannerX10.png";
import { Link } from 'react-router-dom';
import year_title_bg from '../assets/img/update_2/bg/year_title_bg.svg'
import year_title_bg51 from '../assets/img/update_2/bg/year_title_bg51.svg'
import bgbanner2 from '../assets/img/update_2/bg/bannerbg2.png'
import mapx1 from "../assets/img/mapx1.jpg";
import mapx444 from "../assets/img/mapx444.jpg";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import about_6_1 from "../assets/img/update_2/normal/about_6_1.png";
import dishround from "../assets/img/update_2/normal/dishround.png";
import roundpotato from "../assets/img/update_2/normal/roundedpotato.png";
import contractfarm01 from "../assets/img/update_2/icon/contractfarm01.jpg";
import farmicon1 from "../assets/img/update_2/icon/farmicon1.svg";
import farm111 from "../assets/img/update_2/icon/farm111.jpg";
import farming222 from "../assets/img/update_2/icon/farming222.jpg";
import consistent01 from "../assets/img/update_2/icon/consistent01.jpg";
import consistenet111 from "../assets/img/update_2/icon/consistenet111.jpg";
import consistent222 from "../assets/img/update_2/icon/consistent222.jpg";
import quick01 from "../assets/img/update_2/icon/quick01.jpg";
import quick111 from "../assets/img/update_2/icon/quick111.jpg";
import quick222 from "../assets/img/update_2/icon/quick222.jpg";
import longshelf01 from "../assets/img/update_2/icon/longshelf01.jpg";
import longshelf111 from "../assets/img/update_2/icon/longshlef111.jpg";
import longshelf222 from "../assets/img/update_2/icon/longshelf222.jpg";
import tiger01 from "../assets/img/update_2/icon/tiger01.jpg";
import proudlymade111 from "../assets/img/update_2/icon/proudlymade111.jpg";
import tiger222 from "../assets/img/update_2/icon/tiger222.jpg";
import flexibility01 from "../assets/img/update_2/icon/flexibility01.jpg";
import flexibility111 from "../assets/img/update_2/icon/fexibility111.jpg";
import flexibility222 from "../assets/img/update_2/icon/flexibility222.jpg";
import testicardimg from "../assets/img/update_2/normal/testi-card_img.png";
import qualitydrivenupdated1 from "../assets/img/update_2/normal/qualitydrivenupdated1.jpg";
import mission1 from "../assets/img/update_2/normal/mission1.png";
import vision1 from "../assets/img/update_2/normal/vision1.png";
import customerfocuedupdated1 from "../assets/img/update_2/normal/customerfocusedupdated1.jpg";
import td11 from "../assets/img/update_2/normal/td11.jpg";
import backwardintegration1 from "../assets/img/update_2/normal/backwardintegration1.png";
import backint from "../assets/img/update_2/normal/backint.png";
import bgfriesupdated from "../assets/img/update_2/normal/bgfriesupdated.jpg";

import shrimpovingimgx1 from "../assets/img/update_2/normal/shrimpmovingimgx1.png";
import potatosmovingimgx1 from "../assets/img/update_2/normal/potatomovingimgx1.png";

import Footer from './footer';


const About = (props) => {
    const location = useLocation();

    useEffect(() => {
        const { hash } = location;
        if (hash) {
            const id = hash.substring(1);
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);



    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    // const handleClick = () => {
    //     window.location.href = '/Idgnew/about';
    //     window.scrollTo(0, 0);
    // };


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    return (


        <div>
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${bannerbg})` }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">About Us</h1>
                        <ul class="breadcumb-menu">
                            <li><Link to="/Idgnew/home">Home</Link></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            {/* <div className="d-none d-md-block hidearrow">

                <div className="infrastructure-name">
                    <div style={{ marginTop: "-8px" }}>
                        <ul style={{ listStyleType: 'none', padding: 20 }}>
                            <li><h1 style={{ color: 'white', margin: 0, padding: 0 }}>ABOUT US</h1></li>
                            <li>
                                <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass">Home</span> &nbsp;</Link>
                                <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname">About Us</span></span>
                            </li>
                        </ul>
                    </div>
                </div>



                <img
                    width="2000"
                    height="2000"
                    src={aboutbannerx10}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'right',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                />

                



            </div> */}
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "25px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily: 'helvetica', color: 'white' }}>ABOUT US</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily: 'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily: 'helvetica', color: '#F0B323' }}>About Us</span></span>
                                    </li>
                                </ul>

                            </div>

                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={aboutbannerx10}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',

                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>


            {/* <div class="about-sec overflow-hidden mt-5">
                <div class="shape-mockup moving d-none d-lg-block" data-bottom="20%" data-right="0%">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="img-box6">
                                <div class="img1">
                                    <img src={about_6_1} alt="About" />
                                </div>
                                <div class="img2">
                                   
                                    <div class="testi-card-img">
                                <div class="img1">
                                    <img src={testicardimg} alt="Image" />
                                </div>
                                
                                <div class="shape3">
                                    <img src={dishround} alt="Image" />
                                </div>
                            </div>
                                </div>
                                <div class="img2">
                                   
                                    <div class="testi-card-img">
                                
                             
                                <div class="shape3">
                                    <img src={dishround} alt="Image" />
                                </div>
                            </div>
                                </div>
                            
                                
                                
                            </div>
                            
                        </div>
                        <div class="col-xl-6">
                            <div class="title-area mb-30 text-center text-xl-start">
                                <span class="sub-title">About Company</span>
                                <h2 class="sec-title">We make sure Quality & Healthy Foods</h2>
                            </div>
                            <p class="mt-n2 mb-25 text-center text-xl-start">
                                Conveys the commitment of the restaurant to
                                prioritize both quality And health in their food offerings. It implies that the recipe.
                            </p>
                            <div class="checklist style4 mb-25">
                                <ul>
                                    <li>Food Items management</li>
                                    <li>Table reservation</li>
                                    <li>Customer feedback portal</li>
                                    <li>Location-based services</li>
                                </ul>
                            </div>
                            <div class="text-center text-xl-start">
                                <a href="#" class="th-btn rounded-2">See Details<i
                                    class="fa-solid fa-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <br />

            <div class="about-sec overflow-hidden pb-5">
                <div class="container">

                    <div class="row">

                        {/* <div class="col-xl-7">
                            <div class="img-box6">
                                <div class="img1">
                                    <img src={testcardimg} alt="About" />
                                </div>
                                <div class="img2">
                                    <img src={dishround} alt="About" />
                                </div>
                            </div>
                        </div> */}
                        {/* <h2 className="section-heading">
                COMPANY OVERVIEW

            </h2> */}
                    </div>
                </div>
            </div>

            <div class="about-sec overflow-hidden pb-5" style={{ fontFamily: 'Helvetica !important' }}>
                <div class="container">
                    <div class="title-area mb-30  text-center">
                        <h1 className="why-feature_title titlename" style={{ fontFamily: 'helvetica', color: '#0f1138' }}> COMPANY OVERVIEW</h1>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                                <p style={{ fontFamily: 'helvetica' }}>
                                    Icedream Global distinguishes itself as a leading global exporter of frozen food. Established in 2015 under the Companies Act 2013, Icedream Global functions as a part of the renowned GRKC GROUP, which has been a cornerstone in the food and FMCG industry for more than 200 years.This legacy shapes our commitment to quality, innovation, and customer satisfaction.
                                </p>
                            </p>

                            <p class="section-subheading">
                                <p style={{ fontFamily: 'helvetica' }}>
                                    Focused on the processing and packaging of frozen food items such as potatoes and shrimps, Icedream Global efficiently runs its dual factories in Surat, Gujarat. Our facilities are at the forefront of technological innovation. Our team, comprises of skilled professionals with extensive experience in the frozen food industry, ensures the highest standards of quality , production, packaging, and export.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-sec pb-2 mobileviewcss">
                <div class="container-fluid">
                    <div class="title-area mb-30 text-center">
                        {/* <h2 class="sec-title">
                        Our Best Products</h2> */}
                        <div class="title-area mb-30  text-center">
                            <h1 className="why-feature_title titlename" style={{ fontFamily: 'helvetica', color: '#0f1138' }}> OUR BEST PRODUCTS</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 mt- mt-xl-0">
                            <div class="testi-card-img">
                                <div class="">
                                    <img src={testicardimg} alt="Image" />
                                </div>
                                {/* <div class="shape1">
                                    <img src={about_6_1} alt="Image" />
                                </div> */}
                                <div class="shape1">
                                    <img src={shrimpovingimgx1} alt="Image" style={{ height: '300px' }} />
                                </div>

                                <div class="shape3">
                                    <img src={dishround} alt="Image" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 mt- mt-xl-0">
                            <div class="testi-card-img">
                                <br />
                                <br />
                                {/* <div class="shape1">
                                    <img src={bgfriesupdated} alt="Image" />
                                </div> */}
                                <div class="shape1">
                                    <img src={potatosmovingimgx1} alt="Image" style={{ height: '300px' }} />
                                </div>
                                <div class="shape3">
                                    <img src={roundpotato} alt="Image" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div class="col-xl-12" style={{ marginTop: "66px" }}>
                <div class="title-area mb-30 text-center">
                    <span class="sub-title">About Company</span>
                    <h2 class="sec-title">
                        We ensure Freshness in every Frozen Delight.</h2>
                </div>
                <p class="mt-n2 mb-25 text-center">
                    Conveys the commitment of the restaurant to
                    prioritize both quality And health in their food offerings. It implies that the recipe.
                </p>
               
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="checklist style4 mb-25">
                        <ul>
                            <li>Quality Assurance</li>
                            <li>Heritage and Legacy</li>
                            <li>Diverse Product Line</li>
                            <li>Established Compliance</li>
                        </ul>
                    </div>
                </div>


                <div className="text-center">
                    <Link to="/Idgnew/infrastructure" className="th-btn rounded-2">See Details<i className="fa-solid fa-arrow-right ms-2"></i></Link>
                </div>
            </div> */}


            {/* <div className='container-fluid'>
                <div className="mcb-wrap-inner row" style={{ display: "flex" }}>
              
                    <br />
                    <div className="column mcb-column col-12 column_column">
                        <div className="column_attr clearfix align_center bg-cover" style={{
                            backgroundImage: `url(${bg02})`,
                            backgroundPosition: "center",
                            padding: "2%",
                            height: "100%"
                        }}>
                            <div class="title-area mb-30 text-center">
                                <span class="sub-title">About Company</span>
                                <h2 class="sec-title">
                                    We ensure Freshness in every Frozen Delight.</h2>
                            </div>

                            <p class="mt-n2 mb-25 text-center">
                                Conveys the commitment of the restaurant to
                                prioritize both quality And health in their food offerings. It implies that the recipe.
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="checklist style4 mb-25">
                                    <ul>
                                        <li>Quality Assurance</li>
                                        <li>Heritage and Legacy</li>
                                        <li>Diverse Product Line</li>
                                        <li>Established Compliance</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="text-center">
                                <Link to="/Idgnew/infrastructure" className="th-btn rounded-2">See Details<i className="fa-solid fa-arrow-right ms-2"></i></Link>
                            </div>


                        </div>
                    </div>
                </div>
            </div> */}

            <br /> <br /> <br />

            {/* <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title">MISSION</h4>

                            </div>
                            <p>
                                We aim to be the preferred choice for frozen food solutions, delivering exceptional taste, convenience, and value to consumers worldwide. Our mission is to continuously innovate and elevate the frozen food industry through quality, creativity,
                                and customer satisfaction.
                            </p>








                        </div>



                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title">VISION</h4>
                            </div>
                            <p>
                                Our vision is to revolutionize the frozen food industry, setting a new standard of excellence in taste, nutrition, and sustainability. We aim to make frozen meals synonymous with culinary innovation and wholesome goodness. Committed to sourcing the finest ingredients and implementing sustainable practices, we use cutting-edge
                                technology to ensure the highest quality and freshness in every bite. We strive to be the trusted choice
                                for convenient, nourishing frozen food options that enrich lives .
                            </p>








                        </div>
                    </div>
                </div>
            </div> */}
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${mission1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>

                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>MISSION</h4>





                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>We aim to be the preferred choice for frozen food solutions, delivering exceptional taste, convenience, and value to consumers worldwide. Our mission is to continuously innovate and elevate the frozen food industry through quality, creativity, and customer satisfaction.</li>

                            </ul>








                        </div>
                    </div>
                </div>
            </div>

            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>VISION</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>Our vision is to revolutionize the frozen food industry, setting a new standard of excellence in taste, nutrition, and sustainability. We aim to make frozen meals synonymous with culinary innovation and wholesome goodness. Committed to sourcing the finest ingredients and implementing sustainable practices, we use cutting-edge technology to ensure the highest quality and freshness in every bite. We strive to be the trusted choice for convenient, nourishing frozen food options that enrich lives .</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${vision1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <h2 className="section-heading">
                COMPANY OVERVIEW

            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                                IDG distinguishes itself as a leading global exporter of frozen food. Established in 2015 under the Companies Act 1956, IDG functions as a subsidiary of the renowned GRKC GROUP, which has been a cornerstone in the food and FMCG industry for more than 200 years.This legacy shapes our commitment to quality, innovation, and customer satisfaction.
                            </p>

                            <p class="section-subheading">
                                Focused on the processing and packaging of frozen food items such as potatoes and shrimps, IDG efficiently runs its dual factories in Surat, Gujarat. Our facilities are at the forefront of technological innovation. Our team, comprises of skilled professionals with extensive experience in the frozen food industry, ensures the highest standards of quality , production, packaging, and export.
                            </p>


                        </div>
                    </div>
                </div>
            </div> */}


            {/* <h2 className="section-heading">
                MISSION

            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                            We aim to be the preferred choice for frozen food solutions, delivering exceptional taste, convenience, and value to consumers worldwide. Our mission is to continuously innovate and elevate the frozen food industry through quality, creativity, and customer satisfaction.
                            </p>

                           


                        </div>
                    </div>
                </div>
            </div> */}
            {/* <h2 className="section-heading">
                VISION

            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                            Our vision is to revolutionize the frozen food industry, setting a new standard of excellence in taste, nutrition, and sustainability. We aim to make frozen meals synonymous with culinary innovation and wholesome goodness. Committed to sourcing the finest ingredients and implementing sustainable practices, we use cutting-edge technology to ensure the highest quality and freshness in every bite.We strive to be the trusted choice for convenient, nourishing frozen food options that enrich lives.
                            </p>

                           


                        </div>
                    </div>
                </div>
            </div> */}



            {/* <div className='container-fluid'>
                <div className="mcb-wrap-inner row" style={{ display: "flex" }}>
                    <h2 className="section-heading">
                        MISSION

                    </h2>
                    <div className="column mcb-column col-12 column_column">
                        <div className="column_attr clearfix align_center bg-cover" style={{
                            backgroundImage: `url(${bg02})`,
                            backgroundPosition: "center",
                            padding: "2%",
                            height: "100%"
                        }}>

                            <p>We aim to be the preferred choice for frozen food solutions, delivering exceptional taste, convenience, and value to consumers worldwide. Our mission is to continuously innovate and elevate the frozen food industry through quality, creativity, and customer satisfaction.</p>
                        </div>
                    </div>
                </div>
            </div>
            <br /> */}
            {/* <div className='container-fluid'>

                <div className="mcb-wrap-inner row" style={{ display: "flex" }}>
                    <h2 className="section-heading">
                        VISION

                    </h2>
                    <div className="column mcb-column col-12 column_column">
                        <div className="column_attr clearfix align_center bg-cover" style={{
                            backgroundImage: `url(${bg02})`,
                            backgroundPosition: "center",
                            padding: "2%",
                            height: "100%"
                        }}>

                            <p>Our vision is to revolutionize the frozen food industry, setting a new standard of excellence in taste, nutrition, and sustainability. We aim to make frozen meals synonymous with culinary innovation and wholesome goodness. Committed to sourcing the finest ingredients and implementing sustainable practices, we use cutting-edge technology to ensure the highest quality and freshness in every bite.We strive to be the trusted choice for convenient, nourishing frozen food options that enrich lives.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <br /><br />
            <div className="about-sec overflow-hidden pb-5" id="viewmore" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${qualitydrivenupdated1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>



                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>QUALITY DRIVEN</h4>





                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>At Icedream Global ,Quality is at the heart of everything we do.We are dedicated to delivering frozen food products that meet the highest standards of excellence. From the careful selection of top-quality ingredients to our rigorous production processes, we adhere to the highest industry standards. We provide our customers with safe and reliable products that adhere to food safety regulations, aligning with the directives of regulatory bodies and the internal quality policy set forth by the company.</li>

                            </ul>








                        </div>
                    </div>
                </div>
            </div>

            <br />  <br />
            <div className="about-sec overflow-hidden pb-5" id="viewmore2" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>CUSTOMER FOCUSED</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>Customer focus is ingrained in every aspect of our frozen food processing. Our commitment revolves around understanding and meeting the unique needs of our customers. From crafting delicious frozen products to providing personalized service, we prioritize customer satisfaction at every step. Your culinary delight is our primary goal, and we continually strive to achieve the highest level of customer satisfaction , irrespective of the associated costs .</li>

                            </ul>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${customerfocuedupdated1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>


                    </div>
                </div>
            </div>
            <br /> <br />

            <div className="about-sec overflow-hidden pb-5" id="viewmore3" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${td11})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>

                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            {/* text-xl-start */}
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>TECHNOLOGY DRIVEN</h4>





                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>Our dedication to innovation permeates every facet of our operations. From state-of-the-art freezing technologies to advanced quality control systems, we harness cutting-edge solutions to produce top-tier frozen food products. Embracing technology enables us to maintain a leading position in the industry, guaranteeing efficiency, quality, and a smooth experience for our customers.</li>

                            </ul>








                        </div>
                    </div>
                </div>
            </div>
            <br /> <br />

            <div className="about-sec overflow-hidden pb-5" id="viewmore4" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center">
                                <h4 className="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>BACKWARD INTEGRATION</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily: 'helvetica' }}>
                                <li>Customer focus is ingrained in every aspect of our frozen food processing. Our commitment revolves around understanding and meeting the unique needs of our customers. From crafting delicious frozen products to providing personalized service, we prioritize customer satisfaction at every step. Your culinary delight is our primary goal, and we continually strive to achieve the highest level of customer satisfaction , irrespective of the associated costs .</li>

                            </ul>








                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${backint})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '298px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>


                    </div>
                </div>
            </div>
            <br />
            {/* <h2 class="section-heading">
                Mission
            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                                We aim to be the preferred choice for frozen food solutions, delivering exceptional taste, convenience, and value to consumers worldwide. Our mission is to continuously innovate and elevate the frozen food industry through quality, creativity, and customer satisfaction.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <h2 class="section-heading">
                Vision
            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                                Our vision is to revolutionize the frozen food industry, setting a new standard of excellence in taste, nutrition, and sustainability. We aim to make frozen meals synonymous with culinary innovation and wholesome goodness. Committed to sourcing the finest ingredients and implementing sustainable practices, we use cutting-edge technology to ensure the highest quality and freshness in every bite.We
                                strive to be the trusted choice for convenient, nourishing frozen food options that enrich lives.
                            </p>


                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="space" style={{ marginTop: '-120px' }}>
                <h2 className="section-heading" style={{ fontFamily: 'helvetica' }}>
                    Advantage Icedream
                </h2>
                <div class="container">
                    <div class="row gy-4">
                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">

                                    <img src={farming222} alt="Icon" />


                                </div>
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>100% CONTRACT</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>FARMING</a>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">
                                    
                                    <img src={quick222} alt="Icon" />
                                    
                                </div>
                                
                               
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>QUICK</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>TURNAROUND TIME</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">
                                   
                                    <img src={longshelf222} alt="Icon" />

                                </div>
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>LONG SHELF</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>LIFE-18-24 MONTHS</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <br />









                <div class="container">
                    <div class="row gy-4">
                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">
                                   
                                    <img src={consistent222} alt="Icon" />


                                </div>
                                 
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>CONSISTENT</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>QUALITY AND PRICING</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">

                                    
                                    <img src={flexibility222} alt="Icon" />
                                </div>
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>FLEXIBILITY TO</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>CUSTOMIZE</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div class="contact-feature d-flex align-items-center" style={{ maxWidth: '900px', margin: '0 auto', padding: '70px' }}>
                                <div class="contact-feature_icon me-3">

                                  
                                    <img src={tiger222} alt="Icon" />
                                </div>
                                <div>
                                    <h4 class="box-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>PROUDLY MADE</h4>
                                    <span class="contact-feature_text">
                                        <a href="mailto:karan@icedreamglobal.com" style={{ fontFamily: 'helvetica', color: '#0f1138' }}>IN INDIA</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
             <div className="space" style={{ marginTop: '-120px' }}>
                <h2 className="section-heading" style={{ fontFamily: 'helvetica' }}>
                    Advantage Icedream
                </h2>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '70px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={farming222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        100% CONTRACT
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            FARMING
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '60px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={quick222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        QUICK
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            TURNAROUND TIME
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '60px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={longshelf222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        LONG SHELF
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            LIFE-18-24 MONTHS
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '70px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={consistent222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        CONSISTENT
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            QUALITY AND PRICING
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '30px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={flexibility222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        FLEXIBILITY TO
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            CUSTOMIZE
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6 d-flex justify-content-center">
                            <div
                                className="contact-feature d-flex align-items-center"
                                style={{
                                    maxWidth: '900px',
                                    margin: '0 auto',
                                    padding: '90px',
                                }}
                            >
                                <div
                                    className="contact-feature_icon me-3"
                                    style={{ width: '130px', height: '100px' }}
                                >
                                    <img
                                        src={tiger222}
                                        alt="Icon"
                                        style={{
                                            width: '150%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4
                                        className="box-title"
                                        style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                    >
                                        PROUDLY MADE
                                    </h4>
                                    <span className="contact-feature_text">
                                        <a
                                            href="mailto:karan@icedreamglobal.com"
                                            style={{ fontFamily: 'helvetica', color: '#0f1138' }}
                                        >
                                            IN INDIA
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />


           
           

            <div className="d-none d-md-block hidearrow" style={{ marginTop: '-86px' }}>
               
                <div class="title-area text-center" style={{ fontFamily: 'helvetica' }}>

                    <h2 class="sec-title" style={{ fontFamily: 'helvetica', color: '#0f1138' }}> Our Global <span class="" style={{ fontFamily: 'helvetica', color: '#F0B323' }}>Presence</span></h2>

                </div>
                <br />
                <img
                    width="2000"
                    height="700"
                    src={mapx444}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'left',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                />





            </div>
            <br />
            <br />
            {/* <section class="mb-5 mt-5">
                <div class="container">
                    <div class="row gy-4 justify-content-center">
                        <div class="col-xl-3 col-sm-6">
                            <div class="why-feature">
                                <div class="why-feature_icon">
                                    <img src={why_feature_1} alt="icon" />
                                </div>
                                <h3 class="why-feature_title">Quality Foods</h3>
                                <p class="why-feature_text">
                                   
                                    Praesent rutrum ligula ligula, eget viverra neque congue sed. Donec imperdiet diam orci.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="why-feature">
                                <div class="why-feature_icon">
                                    <img src={why_feature_4} alt="icon" />
                                </div>
                                <h3 class="why-feature_title">Expert Chef</h3>
                                <p class="why-feature_text">guarantee your spot, enjoy a hassle-f dining Experience with our</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="why-feature">
                                <div class="why-feature_icon">
                                    <img src={why_feature_2} alt="icon" />
                                </div>
                                <h3 class="why-feature_title">Table reservation</h3>
                                <p class="why-feature_text">Guarantee your spot, and enjoy a hassle-free dining Experience with
                                    our</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="why-feature">
                                <div class="why-feature_icon">
                                    <img src={why_feature_3} alt="icon" />
                                </div>
                                <h3 class="why-feature_title">Online Order</h3>
                                <p class="why-feature_text">Order online and savor the Delicious flavors from the Comfort of
                                    home</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="space" data-overlay="black" data-opacity="6" style={{ backgroundImage: `url(${bgbanner2})` }}>
                <div class="container">
                    <div class="row justify-content-between align-item-center">
                        <div class="col-lg-6 mb-40 mb-lg-0 text-center text-lg-start">
                            <div class="title-area mb-30">
                                <span class="sub-title" style={{ fontFamily: 'helvetica', color: '#F0B323' }}>
                                    {/* NutriFresh Sea Delights */}
                                    Discover the mastery of flavor in our frozen culinary delights.
                                </span>
                                <h2 class="sec-title text-white" style={{ fontFamily: 'helvetica' }}>Experience the artistry of flavor in every exquisite frozen dish</h2>
                            </div>
                            <p class="mt-n2 mb-40 text-white" style={{ fontFamily: 'helvetica' }}>Dive into a Symphony of Flavor with Our frozen Delights, Where Quality and Health are Our Top Priorities, Ensuring Each Dish is as Nourishing as it is Delicious!</p>

                            <Link to="/quality" className="th-btn rounded-2 style3" style={{ fontFamily: 'helvetica', background: '#F0B323' }}>
                                About More<i className="fas fa-arrow-right ms-2"></i>
                            </Link>
                        </div>
                        <div class="col-lg-auto">
                            <div class="experience-year2">
                                <h3 class="text" style={{ fontFamily: 'helvetica', color: '#F0B323' }}>We have</h3>
                                <h4 class="number"><span class="counter-number" style={{ fontFamily: 'helvetica' }}>200</span>+</h4>
                                <h5 className="title" style={{ backgroundImage: `url(${year_title_bg51})`, fontFamily: 'helvetica' }} >Years Experience</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            {/* <section className="mt-5 bg-smoke2" id="testiSec" style={{ backgroundImage: `url(${testi_bg_4})` }}>
                <div class="shape-mockup jump d-none d-xl-block" data-top="40%" data-left="5%"><img
                    src={french_fry} alt="shape" /></div>
                <div class="shape-mockup moving d-none d-xl-block" data-bottom="10%" data-right="4%"><img
                    src={tomato_6} alt="shape" /></div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-4 text-center text-xl-start">
                            <div class="pe-xxl-5">
                                <div class="title-area mb-40">
                                    <span className="sub-title">Customer Feedback</span>
                                    <h2 className="sec-title">What customers Say About Us</h2>
                                </div>

                                <p class="mt-n2 mb-25">
                                  
                                    <br/>They're easy to work with and always deliver a delicious taste. My family always looks forward to meals featuring IDG seafood
                                </p>
                            </div>

                            
                        </div>
                       
                        <div className="col-xl-8 mt-40 mt-xl-0">
                            <Slider {...settings} id="testiSlide3">
                                <div>
                                    <div className="testi-block">
                                        <div class="col-xl-12 col-lg-12">
                                            <div class="testi-block">
                                                <div class="testi-block_shape">
                                                    <img src={testiblock_bg} alt="BG" />
                                                </div>
                                                <div class="testi-block_profile">
                                                    <div class="testi-block_avater">
                                                        <img src={testi_4_1} alt="Avater" />
                                                        <div class="testi-block_quote">
                                                            <img src={quote_1} alt="Icon" />
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <div class="testi-block_review">
                                                            <i class="fa-sharp fa-solid fa-star"></i><i
                                                                class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i>
                                                        </div>
                                                        <h3 class="testi-block_name">Rayan Kook</h3>
                                                        <span class="testi-block_desig">NYC, USA</span>
                                                    </div>
                                                </div>
                                                <p class="testi-block_text">As a food critic, I've tasted seafood from various suppliers. IDG's Indian shrimps stand out for their freshness and flavor. Highly recommended!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="testi-block">
                                        <div class="col-xl-12 col-lg-12">
                                            <div class="testi-block">
                                                <div class="testi-block_shape">
                                                    <img src={testiblock_bg} alt="BG" />
                                                </div>
                                                <div class="testi-block_profile">
                                                    <div class="testi-block_avater">
                                                        <img src={testi_4_2} alt="Avater" />
                                                        <div class="testi-block_quote">
                                                            <img src={quote_1} alt="Icon" />
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <div class="testi-block_review">
                                                            <i class="fa-sharp fa-solid fa-star"></i><i
                                                                class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i>
                                                        </div>
                                                        <h3 class="testi-block_name">Michel Clark</h3>
                                                        <span class="testi-block_desig">DYM, USA</span>
                                                    </div>
                                                </div>
                                                <p class="testi-block_text">I've been buying seafood from IDG for years, and I'm always impressed by the quality of their Indian shrimps. They're perfect for my recipes and always fresh!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="testi-block">
                                        <div class="col-xl-12 col-lg-12">
                                            <div class="testi-block">
                                                <div class="testi-block_shape">
                                                    <img src={testiblock_bg} alt="BG" />
                                                </div>
                                                <div class="testi-block_profile">
                                                    <div class="testi-block_avater">
                                                        <img src={testi_4_3} alt="Avater" />
                                                        <div class="testi-block_quote">
                                                            <img src={quote_1} alt="Icon" />
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <div class="testi-block_review">
                                                            <i class="fa-sharp fa-solid fa-star"></i><i
                                                                class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i>
                                                        </div>
                                                        <h3 class="testi-block_name">Rosse Mons</h3>
                                                        <span class="testi-block_desig">DO, USA</span>
                                                    </div>
                                                </div>
                                                <p class="testi-block_text">I appreciate IDG's commitment to sustainability and quality. Their Indian shrimps are not only delicious but also responsibly sourced, making them a guilt-free choice for me</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <section class="mt-5 mb-5">
                <div class="container">
                    <div class="title-area text-center">
                        <span class="sub-title">Expert Chefs</span>
                        <h2 className="sec-title">Meet our expert team</h2>
                    </div>
                    <div class="row th-carousel" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                        data-sm-slide-show="2" data-xs-slide-show="1" data-arrows="true">
                        <Slider {...settingsscroll}>
                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_1} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Chief Executive</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">David Latham</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_2} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Chief Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Mary Jenica</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_3} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Senior Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Joseph Carter</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_4} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Senior Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Anjelina Rosee</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_5} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Senior Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Rubina Jenny</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_6} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Junior Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Alex Jender</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_7} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Junior Chef</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Jimey Anderson</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4 col-xl-3">
                                <div class="th-team team-box">
                                    <div class="team-img">
                                        <img src={team_2_8} alt="Team" />
                                    </div>
                                    <div class="team-content-wrap">
                                        <span class="team-desig">Chief Executive</span>
                                        <div class="team-content">
                                            <h3 class="team-title"><a href="team-details.html">Josep Brtomeu</a></h3>
                                            <div class="th-social">
                                                <a target="_blank" href="https://facebook.com/"><i
                                                    class="fab fa-facebook-f"></i></a>
                                                <a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
                                                <a target="_blank" href="https://instagram.com/"><i
                                                    class="fab fa-instagram"></i></a>
                                                <a target="_blank" href="https://linkedin.com/"><i
                                                    class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section> */}
            <br /><br />

            <Footer />

        </div>
    );
};

export default About;
