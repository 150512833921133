import React from 'react';
import Footer from './footer';


const Blog = () => {
  return (
   
   
    <div>
      
      <iframe
        src="https://icedreamglobal.com/blog"
        title="Blog"
        width="100%"
        height="1000px"
        style={{ border: 'none' }}
      />
      <br/>
      <br/> <br/> <br/>
       <Footer />
    </div>
   
  );
};

export default Blog;
