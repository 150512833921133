// import React from 'react';
// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import category_6_2 from "../assets/img/update_2/normal/category_6_2.png";
import category_6_3 from "../assets/img/update_2/normal/category_6_3.png";
import category_6_4 from "../assets/img/update_2/normal/category_6_4.png";
import category_6_5 from "../assets/img/update_2/normal/category_6_5.png";
import category_6_7 from "../assets/img/update_2/normal/category_6_7.png";


// Updated shrimps start
import shrimp11 from "../assets/img/update_2/normal/shrimp11.png";
import shrimp12 from "../assets/img/update_2/normal/shrimp12.png";
import shrimp13 from "../assets/img/update_2/normal/shrimp13.png";
import shrimp14 from "../assets/img/update_2/normal/shrimp14.png";
import shrimp15 from "../assets/img/update_2/normal/shrimp15.png";
import shrimp16 from "../assets/img/update_2/normal/shrimp16.png";
import shrimp17 from "../assets/img/update_2/normal/shrimp17.png";
import shrimp18 from "../assets/img/update_2/normal/shrimp18.png";


import breadedshrimptorpedo1 from "../assets/img/update_2/normal/breadedshrimptorpedo1.jpg";
import torpedo011 from "../assets/img/update_2/normal/toprpedo011.png";
import torpedo022 from "../assets/img/update_2/normal/torpedo022.png";
import breadedshrimpfilo1 from "../assets/img/update_2/normal/breadedshrimpfilo1.jpg";
import shrimpfilo02 from "../assets/img/update_2/normal/shrimpfilo02.jpeg";
import breadedbutterfly1 from "../assets/img/update_2/normal/breadedbutterfly1.jpg";
import shrimpban1 from "../assets/img/shrimpban1.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './footer';


const Frozenshrimp = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const galleryImages = [
        { id: 1, src: 'assets/img/update_2/normal/shrimp2.jpeg' },
        { id: 2, src: 'assets/img/update_2/normal/torpedo1.jpeg' },
        { id: 3, src: 'assets/img/update_2/normal/shrimp1.jpeg' },
        { id: 4, src: 'assets/img/update_2/normal/torpedo3.jpeg' },
        { id: 5, src: 'assets/img/update_2/normal/torpedo4.jpeg' },
        { id: 6, src: 'assets/img/update_2/normal/torpedo5.jpeg' },
        { id: 7, src: 'assets/img/update_2/normal/shrimp3.jpeg' },
        { id: 8, src: 'assets/img/update_2/normal/shrimp4.jpeg' },
        { id: 9, src: 'assets/img/update_2/normal/torpedo6.jpeg' },
    ];


    return (
        <div>
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${breadcumb_bg_2})` }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Products</h1>
                        <ul class="breadcumb-menu">
                            <li><Link to="/Idgnew/home">Home</Link></li>
                            <li>Products</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">

                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white' }}>PRODUCTS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Frozen Shrimps</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <img
                            width="2000"
                            height=""
                            src={shrimpban1}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                                height: "",
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <br />
            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Frozen Shrimps
            </h2>
            <div className="container bigcontainer" style={{ fontFamily:'helvetica' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd', borderRadius: '5px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    <thead style={{ backgroundColor: 'black', color: 'white', borderBottom: '1px solid #ddd' }}>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'center', color: 'white', borderBottom: '1px solid #ddd', borderRadius: '5px 5px 0 0' }}>Category</th>
                            <th style={{ padding: '8px', textAlign: 'center', color: 'white', borderBottom: '1px solid #ddd', borderRadius: '5px 5px 0 0' }}>Product</th>
                            <th style={{ padding: '8px', textAlign: 'center', color: 'white', borderBottom: '1px solid #ddd', borderRadius: '5px 5px 0 0' }}>Description</th>
                            <th style={{ padding: '8px', textAlign: 'center', color: 'white', borderBottom: '1px solid #ddd', borderRadius: '5px 5px 0 0' }}>Sizes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan={5} style={{ padding: '8px', backgroundColor: 'orange', border: '1px solid #ddd', borderRadius: '5px' }}>Black Tiger</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Black tiger head on shell On (HOSO)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen/Brine IQF frozen/Semi IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Black tiger head Less Shell On (HLSO)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen/Brine IQF frozen/Semi Iqf</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Black tiger head Less Shell On EZPL (HLSO ezpl)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen/IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Black tiger Peeled and Deveined Tail on -BT PDTO</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen /IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Black tiger Peeled and Deveined Tail off-BT PD</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen/IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td rowSpan={5} style={{ padding: '8px', backgroundColor: 'coral', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei Head on shell On(HOSO)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen /Brine IQF Frozen /Semi Iqf</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei Head Less shell On(HLSO)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen/Brine IQF Frozen /Semi IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei Head Less shell On EZPL(HLSO ezpl)</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen /IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei Peeled and Deviened Tail on - VAN PDTO</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen /IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Vannamei Peeled And Deviened Tail off -VAN PD</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>Block frozen /IQF</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}>4/6 to 91/110</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* <div class="mt-6">
                <div class="container">
                    <div class="row th-carousel" data-slide-show="6" data-ml-slide-show="5" data-lg-slide-show="4"
                        data-md-slide-show="3" data-sm-slide-show="2" data-xs-slide-show="2">
                        <Slider {...settingsscroll}>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={category_6_2} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">

                                        <Link to="/Idgnew/product" className="">
                                            <img src={category_6_3} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={category_6_4} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={category_6_5} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={category_6_7} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div> */}

<div class="mt-6">
                <div class="container">
                    <div class="row th-carousel" data-slide-show="6" data-ml-slide-show="5" data-lg-slide-show="4"
                        data-md-slide-show="3" data-sm-slide-show="2" data-xs-slide-show="2">
                        <Slider {...settingsscroll}>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp11} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">

                                        <Link to="/product" className="">
                                            <img src={shrimp12} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp13} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp14} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp15} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp16} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp17} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/product" className="">
                                            <img src={shrimp18} alt="Image" style={{ height: '290px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>



            {/* <h2 className="section-heading">
                Breaded Shrimps
            </h2>

            <div class="row gy-40">

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp2} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp2.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo1} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo1.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp1} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp1.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo3} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo3.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo4} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo4.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo5} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo5.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>


                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp3} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp3.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp4} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp4.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >

                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo6} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo6.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>

                    </div>
                </div>
            </div>

            <br /> */}

            {/* <div class="title-area mb-30 text-center">
              
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename">VALUE ADDED SHRIMPS</h1>
                </div>
            </div> */}
        {/* <h1 className="section-heading2 bg-light p-3" style={{ fontFamily:'helvetica' }}>
                VALUE ADDED SHRIMPS
            </h1> */}
            <h2 className="section-heading mt-3" style={{ fontFamily:'helvetica' }}>
            VALUE ADDED SHRIMPS
            </h2>
            

            <div class="title-area mb-30 text-center">
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename" style={{ fontFamily:'helvetica' }}>BREADED SHRIMP TORPEDO</h1>
                </div>
            </div>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${torpedo011})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${torpedo022})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered table-striped table-hover text-center" style={{ backgroundColor: '#f8f9fa' }}>
                            <tbody style={{ fontFamily:'helvetica' }}>
                                <tr>
                                    <td>Product Type</td>
                                    <td>Raw Frozen Breaded</td>
                                </tr>
                                <tr>
                                    <td>Type Of Freezing</td>
                                    <td>Semi IQF</td>
                                </tr>
                                <tr>
                                    <td>Shelf Life</td>
                                    <td>24 Months</td>
                                </tr>
                                <tr>
                                    <td>Storage</td>
                                    <td>Keep frozen at -18°C or below</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br /> <br />
            <div class="title-area mb-30 text-center">
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename" style={{ fontFamily:'helvetica' }}>BREADED SHRIMP FILO</h1>
                </div>
            </div>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">

                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${shrimpfilo02})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${breadedshrimpfilo1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered table-striped table-hover text-center" style={{ backgroundColor: '#f8f9fa' }}>
                            <tbody style={{ fontFamily:'helvetica' }}>
                                <tr>
                                    <td>Product Type</td>
                                    <td>Raw Frozen Breaded</td>
                                </tr>
                                <tr>
                                    <td>Type Of Freezing</td>
                                    <td>Semi IQF</td>
                                </tr>
                                <tr>
                                    <td>Shelf Life</td>
                                    <td>24 Months</td>
                                </tr>
                                <tr>
                                    <td>Storage</td>
                                    <td>Keep frozen at -18°C or below</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <br />  <br />
            <div class="title-area mb-30 text-center">
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename" style={{ fontFamily:'helvetica',textTransform: 'uppercase'  }}>Breaded Butterfly</h1>
                </div>
            </div>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${breadedbutterfly1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>

                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${breadedshrimptorpedo1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered table-striped table-hover text-center" style={{ backgroundColor: '#f8f9fa' }}>
                            <tbody style={{ fontFamily:'helvetica' }}>
                                <tr>
                                    <td>Product Type</td>
                                    <td>Raw Frozen Breaded</td>
                                </tr>
                                <tr>
                                    <td>Type Of Freezing</td>
                                    <td>Semi IQF</td>
                                </tr>
                                <tr>
                                    <td>Shelf Life</td>
                                    <td>24 Months</td>
                                </tr>
                                <tr>
                                    <td>Storage</td>
                                    <td>Keep frozen at -18°C or below</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <h2 className="section-heading">Breaded Shrimps</h2>
            <div className="row gy-40">
              
                {galleryImages.map((image, index) => (
                    <div className="col-xl-4 col-lg-4 col-sm-6" key={index}>
                        <div className="th-menu">
                            <div className="">
                                <div className="gallery-card style2 mb-0">
                                    <div className="gallery-img">
                                        <img
                                            style={{ borderRadius: '3%', width: '400px', height: '300px' }}
                                            src={image.src}
                                            alt="gallery image"
                                            onClick={() => openModal(image.src)}
                                        />
                                    </div>
                                    <div className="gallery-content">
                                        <h2 className="gallery-title box-title">Sea food</h2>
                                        <button className="icon-btn popup-image" onClick={() => openModal(image.src)}>
                                            <i className="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="th-menu-content">
                                <h3 className="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                                <p className="th-menu_desc">Freesh Sea Food</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="View Image"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                        width: '60%', 
                        height: '60%', 
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        background: '#fff', 
                        borderRadius: '5px', 
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', 
                    },
                }}
            >
                <img src={selectedImage} alt="Full-screen gallery" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', color: '#000', fontSize: '20px', cursor: 'pointer' }}>Close</button>
            </Modal> */}
            <br /> <br /> <br /> <br />
            <Footer />
        </div>
    );
};

export default Frozenshrimp;
