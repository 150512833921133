import React from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';


const Salesexecutive = () => {
    const handleClick = () => {
        window.location.href = '/about';
        window.scrollTo(0, 0);
    };
    const handleClickTwo = () => {
        window.location.href = '/home';
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white'}}>CAREERS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323' }}>Careers</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                               
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-area mb-30 text-center text-xl-start"><h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Sales Executive</h4>
                            <p className="sec-title" style={{ fontFamily:'helvetica' }}>As a Sales Executive in the Frozen Food industry, you will be responsible for driving sales and revenue growth by acquiring new customers and maintaining strong relationships with existing clients. Your primary objective will be to promote and sell frozen food products to retailers, distributors, and foodservice establishments. This role requires a proactive and results-oriented individual with excellent communication and negotiation skills.</p>
                        </div>
                        <div className="title-area mb-30 text-center text-xl-start">
                            <p className="sec-title" style={{ fontFamily:'helvetica' }}><b>1.</b> Sales Strategy and Planning:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Develop and implement a comprehensive sales strategy to achieve sales targets and maximize market penetration.</li>
                            <li>Identify potential customers and market segments, and create effective sales plans to reach them.</li>
                            <li>Analyze market trends, competitor activities, and customer preferences to identify opportunities for business growth.</li>
                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title"><b>2.</b>New Business Acquisition:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Prospect for new clients and build a robust pipeline of leads through various channels, including cold calling, networking, and referrals.</li>
                            <li>Conduct sales presentations and product demonstrations to showcase the benefits and features of frozen food products.</li>
                            <li>Negotiate pricing, terms, and contracts with potential customers, ensuring profitability and customer satisfaction.</li>
                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title"><b>3.</b>Account Management:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Build and maintain strong relationships with existing customers to drive repeat business and ensure customer loyalty.</li>
                            <li>Provide excellent customer service by promptly addressing inquiries, resolving issues, and ensuring timely delivery of orders.</li>
                            <li>Conduct regular business reviews with customers to identify their evolving needs and upsell additional products or services.</li>
                        </ul>


                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title"><b>4.</b>Market Intelligence and Analysis:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Stay updated on industry trends, market conditions, and competitor activities to identify new business opportunities and stay ahead of the competition.</li>
                            <li>Gather customer feedback and market intelligence to provide valuable insights to the marketing and product development teams.</li>

                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title"><b>5.</b>Sales Reporting and Analysis:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Maintain accurate and up-to-date sales records, including customer information, sales activities, and sales forecasts.</li>
                            <li>Generate regular sales reports and performance analyses to track individual and team progress towards sales targets.</li>

                        </ul>




                        <div className="title-area mb-30 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <p className="sec-title">Qualification and Skills:</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Bachelor’s degree in Business Administration, Marketing, or a related field.</li>
                            <li>Proven experience in sales, preferably in the frozen food industry or a related field.</li>
                            <li>Strong knowledge of frozen food products, their features, and applications.
                                <br />
                                Excellent communication, negotiation, and presentation skills.
                                <br />
                                Ability to build and maintain long-term customer relationships.
                                <br />
                                Self-motivated, results-driven, and able to work independently or as part of a team.
                                <br />
                                Proficiency in using CRM software and MS Office applications.
                            </li>

                            <li>
                                <p>Note: This job description is a general overview of the typical responsibilities and qualifications for a Sales Executive in the Frozen Food industry. Actual job requirements may vary depending on the specific company and its products.</p>
                                <b>Job Category</b>: Sales<br />
                                <b> Job Type</b>: Full Time<br />
                                <b> Job Location</b>: Delhi,India
                            </li>

                        </ul>


                    </div>
                    {/* <div className="col-lg-6">
                        <form className="border p-4">
                            <h2 className="mb-4">Apply for this position</h2>
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Full Name *</label>
                                <input type="text" className="form-control" id="fullName" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email *</label>
                                <input type="email" className="form-control" id="email" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone *</label>
                                <input type="tel" className="form-control" id="phone" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="coverLetter" className="form-label">Cover Letter *</label>
                                <textarea className="form-control" id="coverLetter" rows="4" required></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="resume" className="form-label">Upload CV/Resume *</label>
                                <input type="file" className="form-control" id="resume" accept=".pdf,.doc,.docx" required />
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="agree" required />
                                <label className="form-check-label" htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="th-btn rounded-2">Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                            </div>
                        </form>
                    </div> */}
                     <div className="row">
                     <h2 className="mb-4 text-center" style={{ fontFamily:'helvetica' }}>Apply for this position</h2>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                {/* <h2 className="mb-4">Apply for this position</h2> */}
                                <div className="mb-3">
                                    <label htmlFor="fullName" className="form-label" style={{ fontFamily:'helvetica' }}>Full Name *</label>
                                    <input type="text" className="form-control" style={{ fontFamily:'helvetica' }} id="fullName" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label" style={{ fontFamily:'helvetica' }}>Email *</label>
                                    <input type="email" className="form-control" style={{ fontFamily:'helvetica' }} id="email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label" style={{ fontFamily:'helvetica' }}>Phone *</label>
                                    <input type="tel" className="form-control" style={{ fontFamily:'helvetica' }} id="phone" required />
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                <div className="mb-3">
                                    <label htmlFor="coverLetter" className="form-label" style={{ fontFamily:'helvetica' }}>Cover Letter *</label>
                                    <textarea className="form-control" style={{ fontFamily:'helvetica' }} id="coverLetter" rows="4" required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="resume" className="form-label" style={{ fontFamily:'helvetica' }}>Upload CV/Resume *</label>
                                    <input type="file" className="form-control" style={{ fontFamily:'helvetica' }} id="resume" accept=".pdf,.doc,.docx" required />
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="agree" required  style={{ fontFamily:'helvetica' }}/>
                                    <label className="form-check-label" htmlFor="agree" style={{ fontFamily:'helvetica' }}>By using this form you agree with the storage and handling of your data by this website. *</label>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:'#F0B323'}}>Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br />  <br />  <br />
            <Footer />
            </div>
            );
};

export default Salesexecutive;
