// // import React from 'react';
// // import React, { useRef } from 'react';

// import React, { useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';

// import { Link } from 'react-router-dom';
// import frozenseafood from "../assets/img/frozenseafoodbanner.png";
// import thirdslider from "../assets/img/Third slider 3.png";
// import frozenpatupdated from "../assets/img/frozenpatupdated.png";
// import feature_bg_5 from "../assets/img/update_2/bg/feature_bg_5.png"

// import frozenseafoodx1 from "../assets/img/Sea food 1.jpg";
// import frozenpotatoesx1 from "../assets/img/or.jpg";
// import heritagebanner from "../assets/img/Sea food 4.jpg";
// import Patetos from "../assets/img/potatoes wedges.jpg";

// import dishround from "../assets/img/update_2/normal/dishround.png";
// import roundpotato from "../assets/img/update_2/normal/roundedpotato.png";
// import certifications6 from "../assets/img/certifications6.jpg";
// import certifications4 from "../assets/img/certifications4.jpg";
// import testicardimg from "../assets/img/update_2/normal/testi-card_img.png";
// import testicardshape from "../assets/img/update_2/normal/testi-card_shape_1.png";
// import testicardshapeimg from "../assets/img/update_2/normal/testi-card_shape_2.png";
// import '../assets/css/style.css';
// import '../assets/css/style.css.map';
// import '../assets/css/slick.min.css';
// // import Slider from 'react-slick';
// // import 'slick-carousel/slick/slick.css';
// // import 'slick-carousel/slick/slick-theme.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Footer from './footer';
// import Slider from 'react-slick';

// const Homepage = ({ history }) => {
//     const [showQualityDriven, setShowQualityDriven] = useState(false);

//     const sliderRef = useRef(null);

//     const handleClick = () => {
//         setShowQualityDriven(true);
//     };
//     const [showModal, setShowModal] = useState(false); useEffect(() => {
//         // Slick refresh logic should be handled internally by react-slick
//         if (sliderRef.current) {
//             sliderRef.current.slickGoTo(0); // Optionally go to the first slide on mount
//         }
//     }, []);


//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 1000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2500,
//         prevArrow: <></>,
//         nextArrow: <></>,
//         appendDots: dots => (
//           <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
//             {dots.map((dot, index) => (
//               <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
//                 <div
//                   style={{
//                     width: '10px',
//                     height: '10px',
//                     borderRadius: '50%',
//                     background: index === dot.props.current ? '#ff0000' : '#fff',
//                     border: '0px solid #333',
//                     cursor: 'pointer',
//                     marginTop: '20px',
//                   }}
//                 />
//               </li>
//             ))}
//           </ul>
//         )
//       };
//     const settingstwice = {
//         dots: true,
//         infinite: true,
//         speed: 1000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2500, // Change this value for faster or slower speed

//         appendDots: dots => (
//             <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
//                 {dots.map((dot, index) => (
//                     <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
//                         <div
//                             style={{
//                                 width: '10px',
//                                 height: '10px',
//                                 borderRadius: '50%',
//                                 background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
//                                 border: '0px solid #333', // Customize the border color
//                                 cursor: 'pointer',
//                                 marginTop: '20px',
//                             }}
//                         />
//                     </li>
//                 ))}
//             </ul>
//         )
//     };
//     const settingstestimonal = {
//         dots: true,
//         infinite: true,
//         speed: 600,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         prevArrow: <></>,
//         nextArrow: <></>,
//         appendDots: dots => (
//             <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
//                 {dots.map((dot, index) => (
//                     <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
//                         <div
//                             style={{
//                                 width: '10px',
//                                 height: '10px',
//                                 borderRadius: '50%',
//                                 background: index === dot.props.current ? '#ff0000' : 'red', // Red for active dot, white for others
//                                 border: '0px solid #333', // Customize the border color
//                                 cursor: 'pointer',
//                                 marginTop: '20px',
//                             }}
//                         />
//                     </li>
//                 ))}
//             </ul>
//         )
//     };
//     const settingsscroll = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2000,
//         appendDots: dots => (
//             <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
//                 {dots.map((dot, index) => (
//                     <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
//                         <div
//                             style={{
//                                 width: '10px',
//                                 height: '10px',
//                                 borderRadius: '50%',
//                                 background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
//                                 border: '0px solid #333', // Customize the border color
//                                 cursor: 'pointer',
//                                 marginTop: '20px',
//                             }}
//                         />
//                     </li>
//                 ))}
//             </ul>
//         )
//     };
//     const styles = {
//         textAlign: 'left',
//         fontStyle: 'normal',
//         textDecoration: 'none',
//         textTransform: 'none',
//         letterSpacing: '0px',
//         backgroundPosition: '0% 0%',
//         backgroundRepeat: 'no-repeat',
//         fontFamily: 'Rubik',
//         lineHeight: '54px',
//         top: '259px',
//         left: '15px',
//         color: '#010f1c',
//         fontWeight: '800',
//         fontSize: '44px',
//         whiteSpace: 'normal'
//       };
//     return (
//         <div>
//             <div className="d-none d-md-block hidearrow">
//                 <Slider ref={sliderRef} {...settings}>
//                     <img
//                         width="2000"
//                         height="700"
//                         src={frozenseafoodx1}
//                         className="ls-l ls-img-layer"
//                         alt="image"
//                         decoding="async"
//                         style={{
//                             fontSize: '36px',
//                             color: '#000',
//                             stroke: '#000',
//                             strokeWidth: '0px',
//                             textAlign: 'left',
//                             fontStyle: 'normal',
//                             textDecoration: 'none',
//                             textTransform: 'none',
//                             fontWeight: '400',
//                             letterSpacing: '0px',
//                             backgroundPosition: '0% 0%',
//                             backgroundRepeat: 'no-repeat',
//                             backgroundClip: 'border-box',
//                             overflow: 'visible',
//                             left: '0px',
//                             top: '107px',
//                             WebkitBackgroundClip: 'border-box'
//                         }}
//                         data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
//                     />

//                     <img
//                         width="2000"
//                         height="700"
//                         src={frozenpotatoesx1}
//                         className="ls-l ls-img-layer"
//                         alt="image"
//                         decoding="async"
//                         style={{
//                             fontSize: '36px',
//                             color: '#000',
//                             stroke: '#000',
//                             strokeWidth: '0px',
//                             textAlign: 'right',
//                             fontStyle: 'normal',
//                             textDecoration: 'none',
//                             textTransform: 'none',
//                             fontWeight: '400',
//                             letterSpacing: '0px',
//                             backgroundPosition: '0% 0%',
//                             backgroundRepeat: 'no-repeat',
//                             backgroundClip: 'border-box',
//                             overflow: 'visible',
//                             left: '0px',
//                             top: '107px',
//                             WebkitBackgroundClip: 'border-box'
//                         }}
//                         data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
//                     />

//                     <img
//                         width="2000"
//                         height="700"
//                         src={heritagebanner}

//                         alt="image"

//                         style={{
//                             fontSize: '36px',
//                             color: '#000',
//                             stroke: '#000',
//                             strokeWidth: '0px',
//                             textAlign: 'left',
//                             fontStyle: 'normal',
//                             textDecoration: 'none',
//                             textTransform: 'none',
//                             fontWeight: '400',
//                             letterSpacing: '0px',
//                             backgroundPosition: '0% 0%',
//                             backgroundRepeat: 'no-repeat',
//                             backgroundClip: 'border-box',
//                             overflow: 'visible',
//                             left: '0px',
//                             top: '107px',
//                             WebkitBackgroundClip: 'border-box'
//                         }}
//                         data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
//                     />
//                     <img
//                         width="2000"
//                         height="700"
//                         src={Patetos}

//                         alt="image"

//                         style={{
//                             fontSize: '36px',
//                             color: '#000',
//                             stroke: '#000',
//                             strokeWidth: '0px',
//                             textAlign: 'left',
//                             fontStyle: 'normal',
//                             textDecoration: 'none',
//                             textTransform: 'none',
//                             fontWeight: '400',
//                             letterSpacing: '0px',
//                             backgroundPosition: '0% 0%',
//                             backgroundRepeat: 'no-repeat',
//                             backgroundClip: 'border-box',
//                             overflow: 'visible',
//                             left: '0px',
//                             top: '107px',
//                             WebkitBackgroundClip: 'border-box'
//                         }}
//                         data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
//                     />


//                 </Slider>
//             </div>



            



           
//             <br />
//             <div class="about-sec overflow-hidden pb-5">
//                 <div class="container">
//                     <div class="row">
//                         <div class="col-xl-7 order-2 order-xl-1 mt- mt-xl-0">
//                             <div class="testi-card-img">
//                                 <div class="img1">
//                                     <img src={testicardimg} alt="Image" />
//                                 </div>
//                                 <div class="shape1">
//                                     <img src={testicardshape} alt="Image" />
//                                 </div>
//                                 <div class="shape2">
//                                     <img src={testicardshapeimg} alt="Image" />
//                                 </div>
//                                 <div class="shape3">
//                                     <img src={dishround} alt="Image" />
//                                 </div>
//                                 <div class="shape44x">
//                                     <img src={roundpotato} alt="Image" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col-xl-5 order-1 order-xl-2" style={{ marginTop: "66px" }}>
//                             <div class="title-area mb-30 text-center text-xl-start">
//                                 <span class="sub-title">About Us</span>
//                                 <h2 class="sec-title">
//                                     Delivering Excellence in Frozen Food Products</h2>
//                             </div>
//                             <p class="mt-n2 mb-25 text-center text-xl-start">
//                                 IDG distinguishes itself as a leading global exporter of frozen food. Focused on the processing and packaging of frozen food items such as potatoes and shrimps, IDG efficiently runs its dual factories in Surat, Gujarat. Our facilities are at the forefront of technological innovation. Our team, comprises of skilled professionals with extensive experience in the frozen food industry, ensures the highest standards of quality , production, packaging, and export".
//                             </p>
//                             <div class="checklist style4 mb-25">
//                                 <ul>
//                                     <li>Quality Assurance</li>
//                                     <li>Heritage and Legacy</li>
//                                     <li>Diverse Product Line</li>
//                                     <li>Established Compliance</li>
//                                 </ul>
//                             </div>
//                             <div className="text-center text-xl-start">
//                                 <Link to="/about" className="th-btn rounded-2">See Details<i className="fa-solid fa-arrow-right ms-2"></i></Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* <section class="space-extra bg-auto-repeat bg-title" data-bg-src={feature_bg_5} style={{ backgroundImage: `url(${feature_bg_5})`, position: 'relative', top: '27px' }}>
//                 <div class="container">
//                     <div class="row">
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_1.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title text-center">Quality driven</h3>
//                                 <p class="food-feature_text">

//                                     IDG’s primary objective in any field of business is to provide our customers with safe and
//                                     reliable product adhering to the food safety norms and policy as per the guidelines of the
//                                     statutory authorities and internal quality policy formulated by the company.
//                                 </p>
//                                 <br />
//                                 <Link to="/Idgnew/about" className="th-btn rounded-2">
//                                     Read MORE
//                                     <i className="fa-solid fa-arrow-right ms-2"></i>
//                                 </Link>
//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_2.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title">Customer Focus </h3>
//                                 <p class="food-feature_text">

//                                     IDG has always had a customer centric approach towards all its activities and businesses.

//                                     Our orientation is always focused on customer satisfaction of the highest order
//                                     we aim to cultivate loyalty and long-term partnerships with our customers.
//                                 </p>
//                                 <br />
//                                 <Link to="/Idgnew/about" className="th-btn rounded-2">
//                                     Read MORE
//                                     <i className="fa-solid fa-arrow-right ms-2"></i>
//                                 </Link>
//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title">Technology Driven</h3>
//                                 <p class="food-feature_text">

//                                     IDG has an extensive network of farmers under its wings which help in procurement of all
//                                     types
//                                     of shrimps and seafood from India.

//                                     Wild caught or cultured we are capable and confident enough to provide best quality material
//                                     at
//                                     the cheapest possible prices.
//                                 </p>
//                                 <br />
//                                 <Link to="/Idgnew/about" className="th-btn rounded-2">
//                                     Read MORE
//                                     <i className="fa-solid fa-arrow-right ms-2"></i>
//                                 </Link>
//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" />
//                                 </div>

//                                 <h3 className="food-feature_title box-title" style={{
//                                     whiteSpace: '',
//                                     '@media (max-width: 768px)': { whiteSpace: 'normal' }
//                                 }}>Backward Integration</h3>


//                                 <p class="food-feature_text">

//                                     IDG employs backward integration to ensure quality in every step of the shrimp and seafood production process. By owning or controlling suppliers, distributors, and retail locations, IDG provides high-quality products to customers at competitive prices.
//                                 </p>
//                                 <br />
//                                 <Link to="/Idgnew/about" className="th-btn rounded-2">
//                                     Read MORE
//                                     <i className="fa-solid fa-arrow-right ms-2"></i>
//                                 </Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>



//             </section> */}

//             <section class="space-extra bg-auto-repeat bg-title" data-bg-src={feature_bg_5} style={{ backgroundImage: `url(${feature_bg_5})`, position: 'relative', top: '27px' }}>
//                 <div class="title-area mb-30  text-center">
//                     <h1 className="why-feature_title titlename" style={{ color: 'white' }}>OUR CORE VALUES</h1>
//                 </div>

//                 <div class="container">
//                     <div class="row justify-content-center">
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_1.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title text-center">Quality driven</h3>
//                                 {/* <p class="food-feature_text">

                               
//                                 At IDG ,Quality is at the heart of everything we do.We are dedicated to delivering frozen food products that meet the highest standards of excellence. From the careful selection of top-quality ingredients to our rigorous production processes.
//                                 </p> */}
//                                 <br />
//                                 <div className="d-flex justify-content-center">
//                                     <Link to={{ pathname: "/about", hash: "#viewmore" }} className="th-btn rounded-2 d-flex align-items-center">
//                                         Read MORE
//                                         <i className="fa-solid fa-arrow-right ms-2"></i>
//                                     </Link>
//                                 </div>





//                                 {/* <>
//                                     <div className="d-flex justify-content-center">
//                                         <button
//                                             onClick={() => setShowModal(true)}
//                                             className="th-btn rounded-2 d-flex align-items-center"
//                                         >
//                                             Read MORE
//                                             <i className="fa-solid fa-arrow-right ms-2"></i>
//                                         </button>
//                                     </div>
//                                     <br/>
//                                     {showModal && (

//                                         <div className="modal-bg">
//                                         <div className="modal-content">
//                                             <span className="close-btn" onClick={() => setShowModal(false)}>
//                                                 &times;
//                                             </span>
//                                             <p className='paraPadding'>
//                                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
//                                                 viverra justo nec magna facilisis, sed vestibulum nisl blandit.
//                                                 Donec eu velit interdum, commodo enim et, tempor nisl.
//                                             </p>
//                                         </div>
//                                     </div>
//                                     )}
//                                 </> */}

//                                 {/* <div className="d-flex justify-content-center">
//                                     <button onClick={scrollToQualitySection} className="th-btn rounded-2 d-flex align-items-center">
//                                         Read MORE
//                                         <i className="fa-solid fa-arrow-right ms-2"></i>
//                                     </button>
//                                 </div> */}



//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_2.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title text-center">Customer Focus </h3>
//                                 {/* <p class="food-feature_text">

                                
//                                 Customer focus is ingrained in every aspect of our frozen food processing.Commitment revolves around understanding and meeting the unique needs of our customers, from crafting delicious frozen products to providing personalized service.
//                                 </p> */}
//                                 <br />
//                                 <div className="d-flex justify-content-center">
//                                     <Link to={{ pathname: "/about", hash: "#viewmore2" }} className="th-btn rounded-2">
//                                         Read MORE
//                                         <i className="fa-solid fa-arrow-right ms-2"></i>
//                                     </Link>
//                                 </div>


//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" />
//                                 </div>
//                                 <h3 class="food-feature_title box-title text-center">Technology Driven</h3>

//                                 <br />
//                                 <div className="d-flex justify-content-center">
//                                     <Link to={{ pathname: "/about", hash: "#viewmore3" }} className="th-btn rounded-2">
//                                         Read MORE
//                                         <i className="fa-solid fa-arrow-right ms-2"></i>
//                                     </Link>
//                                 </div>

//                             </div>
//                         </div>
//                         <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_6.svg" alt="Food" style={{ height: "60px" }} />
//                                 </div>
//                                 <h3 class="food-feature_title box-title text-center">Backward Integration</h3>
//                                 <br />
//                                 <div className="d-flex justify-content-center">
//                                     <Link to={{ pathname: "/about", hash: "#viewmore4" }} className="th-btn rounded-2">
//                                         Read MORE
//                                         <i className="fa-solid fa-arrow-right ms-2"></i>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </div>
//                         {/* <div class="col-md-3">
//                             <div class="food-feature"
//                                 style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
//                                 <div class="food-feature_icon d-flex justify-content-center align-item-center">
//                                     <img src="assets/img/update_2/icon/feature_3_6.svg" alt="Food" style={{ height: "60px" }} />
//                                 </div>
                            
                             
//                                 <h3 className="food-feature_title box-title" style={{
//                                     whiteSpace: 'nowrap',
//                                     '@media (max-width: 768px)': {
//                                         whiteSpace: 'normal',
//                                         display: 'inline-block',
//                                         width: '100%',
//                                         padding: '0 10px', 
//                                     },
//                                     '@media (min-width: 769px) and (max-width: 1100px)': {
//                                         whiteSpace: 'normal',
//                                         display: 'inline-block',
//                                         width: '100%',
//                                         padding: '0 10px', 
//                                     },
//                                     '@media (min-width: 1101px)': {
//                                         whiteSpace: 'normal',
//                                         display: 'inline-block',
//                                         width: '100%',
//                                         padding: '0 10px', 
//                                     }
//                                 }}>Backward Integration</h3>


                               
//                                 <br />
//                                 <div className="d-flex justify-content-center">
//                                 <Link to="/Idgnew/about" className="th-btn rounded-2">
//                                     Read MORE
//                                     <i className="fa-solid fa-arrow-right ms-2"></i>
//                                 </Link>
//                                 </div>
//                             </div>
//                         </div> */}
//                     </div>
//                 </div>
//             </section>
//             {/* <section class="position-relative bg-smoke2 pt-5" id="productMenu"
//                 data-bg-src={menu_bg_5} style={{ backgroundImage: `url(${menu_bg_5})`, position: 'relative', top: '-44px' }}>
//                 <div class="container">
//                     <div class="title-area text-center">
//                         <span class="sub-title">Popular Food Menu</span>
//                         <h2 class="sec-title">Choose your best menu</h2>
//                     </div>
//                     <div class="row">
//                         <div class="col-xl-4">
//                             <div class="food-menu-list">

//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_1.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">Frozen Pud Shrimp</a></h3>
//                                         <p class="food-menu_desc">Grilling Churrasco Meat,</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$89.39</span>
//                                 </div>
//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_2.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">Beef Barger</a></h3>
//                                         <p class="food-menu_desc">Fresh from the ocean</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$79.39</span>
//                                 </div>
//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_3.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">Chicken Pizza</a></h3>
//                                         <p class="food-menu_desc">Japanese cuisine Bowl</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$59.39</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col-xl-4">
//                             <div class="menu-image-box">
//                                 <div class="img1">
//                                     <img style={{ width: '400px', height: '20rem' }} src="assets/img/update_2/normal/image_menu.png"
//                                         alt="About" />
//                                 </div>
//                                 <div class="img2 jump">
//                                     <img src="assets/img/update_2/shape/pea_1.png" alt="About" />
//                                 </div>
//                                 <div class="discount_style4"  data-bg-src={discount_bg_2} style={{ backgroundImage: `url(${discount_bg_2})`}}>
//                                     <p class="small-text">Up to</p>
//                                     <h3 class="percentage">32%</h3>
//                                     <p class="small-text">discount</p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col-xl-4">
//                             <div class="food-menu-list">

//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_4.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">Brief Barger</a></h3>
//                                         <p class="food-menu_desc">Grilling Churrasco Meat,</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$89.39</span>
//                                 </div>
//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_5.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">Beef Barbecue</a></h3>
//                                         <p class="food-menu_desc">Fresh from the ocean</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$79.39</span>
//                                 </div>
//                                 <div class="food-menu">
//                                     <div class="food-menu_img">
//                                         <img src="assets/img/update_2/menu/menu_2_6.png" alt="Menu" />
//                                     </div>
//                                     <div class="food-menu_content">
//                                         <h3 class="food-menu_title"> <a href="menu.html">European</a></h3>
//                                         <p class="food-menu_desc">Japanese cuisine Bowl</p>
//                                         <div class="product-rating">
//                                             <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
//                                                 <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
//                                                     5</span>
//                                             </div>
//                                             4K Reviews
//                                         </div>
//                                     </div>
//                                     <span class="food-menu_price">$59.39</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section> */}





//             {/* <section className="mt-3" data-pos-for="#productMenu" data-sec-pos="top-half">
//                 <div className="container">
//                     <div className="booking-area" style={{ backgroundImage: `url(${bookingbg})` }}>
//                         <div className="row align-items-center">
//                             <div className="col-xl-4 text-center text-xl-start">
//                                 <div className="title-area mb-25">

//                                     <span className="sub-title text-white">Need Help?</span>
//                                     <h4 className="sec-title text-white">

//                                         Don't Hesitate to contact us for more informations.
//                                     </h4>
//                                 </div>
//                                 <p className=" text-white mt-n2 mb-n2">

//                                 </p>
//                             </div>
//                             <div className="col-xl-8 mt-40 mt-xl-0">
//                                 <form action="mail.php" method="POST" class="booking-form3">
//                                     <div class="row">
//                                         <div class="form-group col-lg-6 col-sm-6">
//                                             <input type="text" class="form-control rounded-2" id="name" name="name"
//                                                 placeholder="Name" />

//                                         </div>
//                                         <div class="form-group col-lg-6 col-sm-6">
//                                             <input type="tel" class="form-control rounded-2" id="number" name="number"
//                                                 placeholder="Phone Number" />
//                                             <i class="far fa-phone"></i>
//                                         </div>



//                                         <div class="form-group col-lg-8 col-sm-6">
//                                             <input type="text" class="form-control rounded-2" id="message" name="message"
//                                                 placeholder="Message" />
//                                             <i class="far fa-comment"></i>
//                                         </div>
//                                         <div class="form-btn col-lg-4 col-sm-6">
//                                             <button class="th-btn style5 rounded-2 w-100">Send Message<i
//                                                 class="fa-solid fa-arrow-right ms-2"></i></button>
//                                             <Link to="/Idgnew/contactus" className="th-btn style5 rounded-2 w-100">
//                                                 Send Message<i className="fa-solid fa-arrow-right ms-2"></i>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                     <p class="form-messages mb-0 mt-3"></p>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>


//             </section> */}
//              <section style={{ marginTop: "80px" }}>
//                 <div class="container">
//                     <div class="row align-items-center">
//                         <div class="col-xl-4 text-center text-xl-start">
//                             <div class="title-area mb-25">
//                                 <span class="sub-title">Why Choose Us</span>
//                                 <h2 class="sec-title">Elevating Frozen Food Excellence for Over Two Centuries</h2>
//                             </div>
//                             <p class="mt-n2 mb-30">Choose IDG for a legacy of over 200 years, delivering excellence in frozen
//                                 food products with a steadfast commitment to quality, innovation, and customer satisfaction.</p>
//                             {/* <a href="#" class="th-btn rounded-2">ABOUT MORE<i
//                                 class="fa-solid fa-arrow-right ms-2"></i></a> */}
//                             <Link to="/about" className="th-btn rounded-2">
//                                 ABOUT MORE
//                                 <i className="fa-solid fa-arrow-right ms-2"></i>
//                             </Link>
//                         </div>
//                         <div class="col-xl-8 mt-40 mt-xl-0">
//                             <div class="row gy-4 justify-content-center">
//                                 <div class="col-lg-4 col-sm-6">
//                                     <div class="why-feature">
//                                         <div class="why-feature_icon">
//                                             <img src="assets/img/update_2/icon/why_feature_1.svg" alt="icon" />
//                                         </div>
//                                         <h3 class="why-feature_title">Quality Assurance</h3>
//                                         <p class="why-feature_text">
//                                             Our state-of-the-art factory ensures that each product meets the highest standards of safety, reliability, and quality.</p>

//                                     </div>
//                                 </div>
//                                 <div class="col-lg-4 col-sm-6">
//                                     <div class="why-feature">
//                                         <div class="why-feature_icon">
//                                             <img src="assets/img/update_2/icon/why_feature_2.svg" alt="icon" />
//                                         </div>
//                                         <h3 class="why-feature_title">Customer-Centric Approach</h3>
//                                         <p class="why-feature_text">
//                                             With a focus on building long-term relationships, we prioritize customer satisfaction above all.
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div class="col-lg-4 col-sm-6">
//                                     <div class="why-feature">
//                                         <div class="why-feature_icon">
//                                             <img src="assets/img/update_2/icon/why_feature_3.svg" alt="icon" />
//                                         </div>
//                                         <h3 class="why-feature_title">Expertise & Infrastructure:</h3>
//                                         <p class="why-feature_text">With over 200 years of experience in the food and FMCG
//                                             industry through GRKC Group.</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             {/* <section className="overflow-hidden space">

//                 <div class="container">
//                     <div class="row">
//                         <div class="col-xl-6">
//                             <div class="pe-xxl-5">
//                                 <div className="title-area mb-0 text-center text-xl-start">
//                                     <span className="sub-title">Testimonials</span>
//                                     <h2 className="sec-title">What customer saying About Services</h2>
//                                 </div>
//                             </div>
//                             <div className="testi-card-slide">
//                                 <div className="row th-carousel number-dots" data-slide-show="1" data-lg-slide-show="2"
//                                     data-md-slide-show="1" data-dots="true" data-xl-dots="true" data-ml-dots="true"
//                                     data-lg-dots="true" data-md-dots="true">
                                        
//                                     <Slider {...settingstestimonal}>
//                                         <div>
//                                             <p>
                                              
//                                                 <div class="testi-block">
//                                                     <div class="testi-block_shape">
//                                                         <img src={testiblock_bg} alt="BG" />
//                                                     </div>
//                                                     <div class="testi-block_profile">
//                                                         <div class="testi-block_avater">
//                                                             <img src={testi_4_1} alt="Avater" />
//                                                             <div class="testi-block_quote">
//                                                                 <img src={quote_1} alt="Icon" />
//                                                             </div>
//                                                         </div>
//                                                         <div class="media-body">
//                                                             <div class="testi-block_review">
//                                                                 <i class="fa-sharp fa-solid fa-star"></i><i
//                                                                     class="fa-sharp fa-solid fa-star"></i><i
//                                                                         class="fa-sharp fa-solid fa-star"></i><i
//                                                                             class="fa-sharp fa-solid fa-star"></i><i
//                                                                                 class="fa-sharp fa-solid fa-star"></i>
//                                                             </div>
//                                                             <h3 class="testi-block_name">Rayan Kook</h3>
//                                                             <span class="testi-block_desig">NYC, USA</span>
//                                                         </div>
//                                                     </div>
//                                                     <p class="testi-block_text">As a passionate restaurant owner, ensuring that our dishes delight our customers is paramount. IDG has been our steadfast partner for sourcing top-quality Indian shrimps, a key ingredient in many of our signature dishes. Their consistent excellence in providing shrimps of perfect size, flavor, and texture has been instrumental in maintaining our reputation for culinary excellence.</p>
//                                                 </div>
//                                             </p>


//                                         </div>
//                                         <div>
//                                             <p>
                                              
//                                                 <div class="testi-block">
//                                                     <div class="testi-block_shape">
//                                                         <img src={testiblock_bg} alt="BG" />
//                                                     </div>
//                                                     <div class="testi-block_profile">
//                                                         <div class="testi-block_avater">
//                                                             <img src={testi_4_2} alt="Avater" />
//                                                             <div class="testi-block_quote">
//                                                                 <img src={quote_1} alt="Icon" />
//                                                             </div>
//                                                         </div>
//                                                         <div class="media-body">
//                                                             <div class="testi-block_review">
//                                                                 <i class="fa-sharp fa-solid fa-star"></i><i
//                                                                     class="fa-sharp fa-solid fa-star"></i><i
//                                                                         class="fa-sharp fa-solid fa-star"></i><i
//                                                                             class="fa-sharp fa-solid fa-star"></i><i
//                                                                                 class="fa-sharp fa-solid fa-star"></i>
//                                                             </div>
//                                                             <h3 class="testi-block_name">Michel Clark</h3>
//                                                             <span class="testi-block_desig">DYM, USA</span>
//                                                         </div>
//                                                     </div>
//                                                     <p class="testi-block_text"> IDG's commitment to sustainable practices is what sets them apart in the competitive seafood market. As a consumer conscious of environmental impact, knowing that I'm purchasing ethically sourced Indian shrimps from IDG gives me peace of mind.</p>

//                                                 </div>
//                                             </p>
//                                         </div>
//                                         <div>
//                                             <p>
                                               
//                                                 <div class="testi-block">
//                                                     <div class="testi-block_shape">
//                                                         <img src={testiblock_bg} alt="BG" />
//                                                     </div>
//                                                     <div class="testi-block_profile">
//                                                         <div class="testi-block_avater">
//                                                             <img src={testi_4_3} alt="Avater" />
//                                                             <div class="testi-block_quote">
//                                                                 <img src={quote_1} alt="Icon" />
//                                                             </div>
//                                                         </div>
//                                                         <div class="media-body">
//                                                             <div class="testi-block_review">
//                                                                 <i class="fa-sharp fa-solid fa-star"></i><i
//                                                                     class="fa-sharp fa-solid fa-star"></i><i
//                                                                         class="fa-sharp fa-solid fa-star"></i><i
//                                                                             class="fa-sharp fa-solid fa-star"></i><i
//                                                                                 class="fa-sharp fa-solid fa-star"></i>
//                                                             </div>
//                                                             <h3 class="testi-block_name">Rosse Mons</h3>
//                                                             <span class="testi-block_desig">DO, USA</span>
//                                                         </div>
//                                                     </div>
//                                                     <p class="testi-block_text">Commitment to quality, reliability, and sustainability is unmatched. We've been consistently impressed with the freshness and taste of their Indian shrimps, and their seamless logistics ensure timely deliveries every time.</p>
//                                                 </div>
//                                             </p>
//                                         </div>
//                                     </Slider>

//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col-xl-6 mt- mt-xl-0">
//                             <div class="testi-card-img">
//                                 <div class="img1">
//                                     <img src={testicardimg} alt="Image" />
//                                 </div>
//                                 <div class="shape1">
//                                     <img src={testicardshape} alt="Image" />
//                                 </div>
//                                 <div class="shape2">
//                                     <img src="assets/img/update_2/normal/testi-card_shape_2.png" alt="Image" />
//                                 </div>
//                                 <div class="shape3">
//                                     <img src="assets/img/update_2/normal/testi-card_shape_3.png" alt="Image" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section> */}

//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <section className="overflow-hidden space" style={{ marginTop: '-50px', padding: '20px' }}>
//                 <div class="title-area mb-30  text-center">
//                     <h1 className="why-feature_title titlename">OUR CERTIFICATIONS</h1>
//                 </div>
//                 <Slider {...settingstwice}>
//                     <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
//                         <img className="gray" src={certifications6} alt="Brand Logo" />
//                     </div>
//                     <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
//                         <img className="gray" src={certifications4} alt="Brand Logo" />
//                     </div>
//                 </Slider>

//             </section>
//             <br /><br /><br /><br /><br />
//             <Footer />
            
//         </div>
//     );
// };

// export default Homepage;




 // import React from 'react';
// import React, { useRef } from 'react';

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import custfocus333 from "../assets/img/update_2/icon/custfocus333.jpg";
import custfocus444 from "../assets/img/update_2/icon/custfocus444.png";
import quality333 from "../assets/img/update_2/icon/quality333.jpg";
import techdriven333 from "../assets/img/update_2/icon/techdriven333.jpg";
import backint333 from "../assets/img/update_2/icon/backint333.jpg";


import quality444 from "../assets/img/update_2/icon/quality444.png";
import techdriven444 from "../assets/img/update_2/icon/techdriven444.png";
import backint444 from "../assets/img/update_2/icon/backint444.png";


import frozenseafood from "../assets/img/frozenseafoodbanner.png";
import thirdslider from "../assets/img/Third slider 3.png";
import frozenpatupdated from "../assets/img/frozenpatupdated.png";
import feature_bg_5 from "../assets/img/update_2/bg/feature_bg_5.png"
import feature_bg_51 from "../assets/img/update_2/bg/feature_bg_51.jpg"

import frozenseafoodx1 from "../assets/img/Sea food 1.jpg";
import frozenpotatoesx1 from "../assets/img/or.jpg";
import heritagebanner from "../assets/img/Sea food 4.jpg";
import Patetos from "../assets/img/potatoes wedges.jpg";

import dishround from "../assets/img/update_2/normal/dishround.png";
import roundpotato from "../assets/img/update_2/normal/roundedpotato.png";
import certifications6 from "../assets/img/certifications6.jpg";
import certifications4 from "../assets/img/certifications4.jpg";
import testicardimg from "../assets/img/update_2/normal/testi-card_img.png";
import testicardshape from "../assets/img/update_2/normal/testi-card_shape_1.png";
import testicardshapeimg from "../assets/img/update_2/normal/testi-card_shape_2.png";
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './footer';



const Homepage = ({ history }) => {
      
    const [showQualityDriven, setShowQualityDriven] = useState(false);

    const handleClick = () => {
        setShowQualityDriven(true);
    };
    const [showModal, setShowModal] = useState(false);


    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500, // Change this value for faster or slower speed
        prevArrow: <></>,
        nextArrow: <></>,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#F0B323' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settingstwice = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500, // Change this value for faster or slower speed

        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#F0B323' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settingstestimonal = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <></>,
        nextArrow: <></>,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : 'red', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const styles = {
        textAlign: 'left',
        fontStyle: 'normal',
        textDecoration: 'none',
        textTransform: 'none',
        letterSpacing: '0px',
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'Rubik',
        lineHeight: '54px',
        top: '259px',
        left: '15px',
        color: '#010f1c',
        fontWeight: '800',
        fontSize: '44px',
        whiteSpace: 'normal'
      };
    return (

        <div>
            <div className="d-none d-md-block hidearrow">
                <Slider {...settings}>
                    <img
                        width="2000"
                        height="700"
                        src={frozenseafoodx1}
                        className="ls-l ls-img-layer"
                        alt="image"
                        decoding="async"
                        style={{
                            fontSize: '36px',
                            color: '#000',
                            stroke: '#000',
                            strokeWidth: '0px',
                            textAlign: 'left',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            backgroundPosition: '0% 0%',
                            backgroundRepeat: 'no-repeat',
                            backgroundClip: 'border-box',
                            overflow: 'visible',
                            left: '0px',
                            top: '107px',
                            WebkitBackgroundClip: 'border-box'
                        }}
                        data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                    />

                    <img
                        width="2000"
                        height="700"
                        src={frozenpotatoesx1}
                        className="ls-l ls-img-layer"
                        alt="image"
                        decoding="async"
                        style={{
                            fontSize: '36px',
                            color: '#000',
                            stroke: '#000',
                            strokeWidth: '0px',
                            textAlign: 'right',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            backgroundPosition: '0% 0%',
                            backgroundRepeat: 'no-repeat',
                            backgroundClip: 'border-box',
                            overflow: 'visible',
                            left: '0px',
                            top: '107px',
                            WebkitBackgroundClip: 'border-box'
                        }}
                        data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                    />

                    <img
                        width="2000"
                        height="700"
                        src={heritagebanner}

                        alt="image"

                        style={{
                            fontSize: '36px',
                            color: '#000',
                            stroke: '#000',
                            strokeWidth: '0px',
                            textAlign: 'left',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            backgroundPosition: '0% 0%',
                            backgroundRepeat: 'no-repeat',
                            backgroundClip: 'border-box',
                            overflow: 'visible',
                            left: '0px',
                            top: '107px',
                            WebkitBackgroundClip: 'border-box'
                        }}
                        data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                    />
                    <img
                        width="2000"
                        height="700"
                        src={Patetos}

                        alt="image"

                        style={{
                            fontSize: '36px',
                            color: '#000',
                            stroke: '#000',
                            strokeWidth: '0px',
                            textAlign: 'left',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            backgroundPosition: '0% 0%',
                            backgroundRepeat: 'no-repeat',
                            backgroundClip: 'border-box',
                            overflow: 'visible',
                            left: '0px',
                            top: '107px',
                            WebkitBackgroundClip: 'border-box'
                        }}
                        data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                    />


                </Slider>
            </div>



            



           
            <br />
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-7 order-2 order-xl-1 mt- mt-xl-0">
                            <div class="testi-card-img">
                                <div class="img1">
                                    <img src={testicardimg} alt="Image" />
                                </div>
                                <div class="shape1">
                                    <img src={testicardshape} alt="Image" />
                                </div>
                                <div class="shape2">
                                    <img src={testicardshapeimg} alt="Image" />
                                </div>
                                <div class="shape3">
                                    <img src={dishround} alt="Image" />
                                </div>
                                <div class="shape44x">
                                    <img src={roundpotato} alt="Image" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 order-1 order-xl-2" style={{ marginTop: "66px" }}>
                            <div class="title-area mb-30 text-center text-xl-start">
                                <span class="sub-title" style={{ fontFamily:'helvetica' }}><span style={{color:"#F0B323"}}>ABOUT US</span></span>
                                <h2 class="" style={{ fontFamily:'helvetica',color:'#0f1138 !important'}}>
                                    Delivering Excellence in Frozen Food Products</h2>
                            </div>
                            <p class="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            Icedream Global distinguishes itself as a leading global exporter of frozen food. Focused on the processing and packaging of frozen food items such as potatoes and shrimps, Icedream Global efficiently runs its dual factories in Surat, Gujarat. Our facilities are at the forefront of technological innovation. Our team, comprises of skilled professionals with extensive experience in the frozen food industry, ensures the highest standards of quality, production, packaging, and export.
                            </p>
                            <div class="checklist style4 mb-25" style={{ fontFamily:'helvetica' }}>
                                <ul>
                                    <li>Quality Assurance</li>
                                    <li>Heritage and Legacy</li>
                                    <li>Diverse Product Line</li>
                                    <li>Established Compliance</li>
                                </ul>
                            </div>
    

                            <div className="text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <Link to="/about" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:"#F0B323"}}>See Details<i className="fa-solid fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <section class="space-extra bg-auto-repeat bg-title" data-bg-src={feature_bg_5} style={{ backgroundImage: `url(${feature_bg_5})`, position: 'relative', top: '27px' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    <img src="assets/img/update_2/icon/feature_3_1.svg" alt="Food" />
                                </div>
                                <h3 class="food-feature_title box-title text-center">Quality driven</h3>
                                <p class="food-feature_text">

                                    IDG’s primary objective in any field of business is to provide our customers with safe and
                                    reliable product adhering to the food safety norms and policy as per the guidelines of the
                                    statutory authorities and internal quality policy formulated by the company.
                                </p>
                                <br />
                                <Link to="/Idgnew/about" className="th-btn rounded-2">
                                    Read MORE
                                    <i className="fa-solid fa-arrow-right ms-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    <img src="assets/img/update_2/icon/feature_3_2.svg" alt="Food" />
                                </div>
                                <h3 class="food-feature_title box-title">Customer Focus </h3>
                                <p class="food-feature_text">

                                    IDG has always had a customer centric approach towards all its activities and businesses.

                                    Our orientation is always focused on customer satisfaction of the highest order
                                    we aim to cultivate loyalty and long-term partnerships with our customers.
                                </p>
                                <br />
                                <Link to="/Idgnew/about" className="th-btn rounded-2">
                                    Read MORE
                                    <i className="fa-solid fa-arrow-right ms-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" />
                                </div>
                                <h3 class="food-feature_title box-title">Technology Driven</h3>
                                <p class="food-feature_text">

                                    IDG has an extensive network of farmers under its wings which help in procurement of all
                                    types
                                    of shrimps and seafood from India.

                                    Wild caught or cultured we are capable and confident enough to provide best quality material
                                    at
                                    the cheapest possible prices.
                                </p>
                                <br />
                                <Link to="/Idgnew/about" className="th-btn rounded-2">
                                    Read MORE
                                    <i className="fa-solid fa-arrow-right ms-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" />
                                </div>

                                <h3 className="food-feature_title box-title" style={{
                                    whiteSpace: '',
                                    '@media (max-width: 768px)': { whiteSpace: 'normal' }
                                }}>Backward Integration</h3>


                                <p class="food-feature_text">

                                    IDG employs backward integration to ensure quality in every step of the shrimp and seafood production process. By owning or controlling suppliers, distributors, and retail locations, IDG provides high-quality products to customers at competitive prices.
                                </p>
                                <br />
                                <Link to="/Idgnew/about" className="th-btn rounded-2">
                                    Read MORE
                                    <i className="fa-solid fa-arrow-right ms-2"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



            </section> */}

            <section class="space-extra bg-auto-repeat bg-title" data-bg-src={feature_bg_51} style={{ backgroundImage: `url(${feature_bg_51})`, position: 'relative', top: '27px' }}>
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename helvetica" style={{ color: 'white',fontFamily:'helvetica' }} >OUR CORE VALUES</h1>
                </div>

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    {/* <img src="assets/img/update_2/icon/feature_3_1.svg" alt="Food" /> */}
                                    <img src={quality444} alt="Icon" />
                                </div>
                                <h3 class="food-feature_title box-title text-center" style={{ fontFamily:'helvetica' }}>Quality driven</h3>
                                {/* <p class="food-feature_text">

                               
                                At IDG ,Quality is at the heart of everything we do.We are dedicated to delivering frozen food products that meet the highest standards of excellence. From the careful selection of top-quality ingredients to our rigorous production processes.
                                </p> */}
                                <br />
                                <div className="d-flex justify-content-center">
                                    <Link to={{ pathname: "/about", hash: "#viewmore" }} className="th-btn rounded-2 d-flex align-items-center" style={{ fontFamily:'helvetica',background:"#F0B323"}}>
                                        Read MORE
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>





                                {/* <>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={() => setShowModal(true)}
                                            className="th-btn rounded-2 d-flex align-items-center"
                                        >
                                            Read MORE
                                            <i className="fa-solid fa-arrow-right ms-2"></i>
                                        </button>
                                    </div>
                                    <br/>
                                    {showModal && (

                                        <div className="modal-bg">
                                        <div className="modal-content">
                                            <span className="close-btn" onClick={() => setShowModal(false)}>
                                                &times;
                                            </span>
                                            <p className='paraPadding'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                                                viverra justo nec magna facilisis, sed vestibulum nisl blandit.
                                                Donec eu velit interdum, commodo enim et, tempor nisl.
                                            </p>
                                        </div>
                                    </div>
                                    )}
                                </> */}

                                {/* <div className="d-flex justify-content-center">
                                    <button onClick={scrollToQualitySection} className="th-btn rounded-2 d-flex align-items-center">
                                        Read MORE
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </button>
                                </div> */}



                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    
                                {/* <img src="assets/img/update_2/icon/feature_3_2.svg" alt="Food" /> */}
                                <img src={custfocus444} alt="Icon" />
                                {/* <img src="assets/img/update_2/icon/sampleicon3.svg" alt="Food" style={{ maxWidth: '26%', maxHeight: '100%' }}/> */}
                                    {/* <img src="assets/img/update_2/icon/featurebgx.svg" alt="Food" style={{ maxWidth: '100%', maxHeight: '100%' }}/> */}
                                </div>
                                <h3 class="food-feature_title box-title text-center" style={{ fontFamily:'helvetica' }}>Customer Focus </h3>
                                {/* <p class="food-feature_text">

                                
                                Customer focus is ingrained in every aspect of our frozen food processing.Commitment revolves around understanding and meeting the unique needs of our customers, from crafting delicious frozen products to providing personalized service.
                                </p> */}
                                <br />
                                <div className="d-flex justify-content-center">
                                    <Link to={{ pathname: "/about", hash: "#viewmore2" }} className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:"#F0B323"}}>
                                        Read MORE
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>


                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    {/* <img src="assets/img/update_2/icon/feature_3_3.svg" alt="Food" /> */}
                                    <img src={techdriven444} alt="Icon" />
                                </div>
                                <h3 class="food-feature_title box-title text-center" style={{ fontFamily:'helvetica' }}>Technology Driven</h3>

                                <br />
                                <div className="d-flex justify-content-center">
                                    <Link to={{ pathname: "/about", hash: "#viewmore3" }} className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:"#F0B323"}}>
                                        Read MORE
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    {/* <img src="assets/img/update_2/icon/feature_3_6.svg" alt="Food" style={{ height: "60px" }} /> */}
                                    <img src={backint444} alt="Icon" />
                                </div>
                                <h3 class="food-feature_title box-title text-center" style={{ fontFamily:'helvetica' }}>Backward Integration</h3>
                                <br />
                                <div className="d-flex justify-content-center">
                                    <Link to={{ pathname: "/about", hash: "#viewmore4" }} className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:"#F0B323"}}>
                                        Read MORE
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-md-3">
                            <div class="food-feature"
                                style={{ padding: '20px', marginBottom: '30px', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                                <div class="food-feature_icon d-flex justify-content-center align-item-center">
                                    <img src="assets/img/update_2/icon/feature_3_6.svg" alt="Food" style={{ height: "60px" }} />
                                </div>
                            
                             
                                <h3 className="food-feature_title box-title" style={{
                                    whiteSpace: 'nowrap',
                                    '@media (max-width: 768px)': {
                                        whiteSpace: 'normal',
                                        display: 'inline-block',
                                        width: '100%',
                                        padding: '0 10px', 
                                    },
                                    '@media (min-width: 769px) and (max-width: 1100px)': {
                                        whiteSpace: 'normal',
                                        display: 'inline-block',
                                        width: '100%',
                                        padding: '0 10px', 
                                    },
                                    '@media (min-width: 1101px)': {
                                        whiteSpace: 'normal',
                                        display: 'inline-block',
                                        width: '100%',
                                        padding: '0 10px', 
                                    }
                                }}>Backward Integration</h3>


                               
                                <br />
                                <div className="d-flex justify-content-center">
                                <Link to="/Idgnew/about" className="th-btn rounded-2">
                                    Read MORE
                                    <i className="fa-solid fa-arrow-right ms-2"></i>
                                </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <section class="position-relative bg-smoke2 pt-5" id="productMenu"
                data-bg-src={menu_bg_5} style={{ backgroundImage: `url(${menu_bg_5})`, position: 'relative', top: '-44px' }}>
                <div class="container">
                    <div class="title-area text-center">
                        <span class="sub-title">Popular Food Menu</span>
                        <h2 class="sec-title">Choose your best menu</h2>
                    </div>
                    <div class="row">
                        <div class="col-xl-4">
                            <div class="food-menu-list">

                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_1.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">Frozen Pud Shrimp</a></h3>
                                        <p class="food-menu_desc">Grilling Churrasco Meat,</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$89.39</span>
                                </div>
                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_2.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">Beef Barger</a></h3>
                                        <p class="food-menu_desc">Fresh from the ocean</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$79.39</span>
                                </div>
                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_3.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">Chicken Pizza</a></h3>
                                        <p class="food-menu_desc">Japanese cuisine Bowl</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$59.39</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="menu-image-box">
                                <div class="img1">
                                    <img style={{ width: '400px', height: '20rem' }} src="assets/img/update_2/normal/image_menu.png"
                                        alt="About" />
                                </div>
                                <div class="img2 jump">
                                    <img src="assets/img/update_2/shape/pea_1.png" alt="About" />
                                </div>
                                <div class="discount_style4"  data-bg-src={discount_bg_2} style={{ backgroundImage: `url(${discount_bg_2})`}}>
                                    <p class="small-text">Up to</p>
                                    <h3 class="percentage">32%</h3>
                                    <p class="small-text">discount</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="food-menu-list">

                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_4.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">Brief Barger</a></h3>
                                        <p class="food-menu_desc">Grilling Churrasco Meat,</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$89.39</span>
                                </div>
                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_5.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">Beef Barbecue</a></h3>
                                        <p class="food-menu_desc">Fresh from the ocean</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$79.39</span>
                                </div>
                                <div class="food-menu">
                                    <div class="food-menu_img">
                                        <img src="assets/img/update_2/menu/menu_2_6.png" alt="Menu" />
                                    </div>
                                    <div class="food-menu_content">
                                        <h3 class="food-menu_title"> <a href="menu.html">European</a></h3>
                                        <p class="food-menu_desc">Japanese cuisine Bowl</p>
                                        <div class="product-rating">
                                            <div class="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                                <span style={{ width: '75%' }}>Rated <strong class="rating">4.00</strong> out of
                                                    5</span>
                                            </div>
                                            4K Reviews
                                        </div>
                                    </div>
                                    <span class="food-menu_price">$59.39</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}





            {/* <section className="mt-3" data-pos-for="#productMenu" data-sec-pos="top-half">
                <div className="container">
                    <div className="booking-area" style={{ backgroundImage: `url(${bookingbg})` }}>
                        <div className="row align-items-center">
                            <div className="col-xl-4 text-center text-xl-start">
                                <div className="title-area mb-25">

                                    <span className="sub-title text-white">Need Help?</span>
                                    <h4 className="sec-title text-white">

                                        Don't Hesitate to contact us for more informations.
                                    </h4>
                                </div>
                                <p className=" text-white mt-n2 mb-n2">

                                </p>
                            </div>
                            <div className="col-xl-8 mt-40 mt-xl-0">
                                <form action="mail.php" method="POST" class="booking-form3">
                                    <div class="row">
                                        <div class="form-group col-lg-6 col-sm-6">
                                            <input type="text" class="form-control rounded-2" id="name" name="name"
                                                placeholder="Name" />

                                        </div>
                                        <div class="form-group col-lg-6 col-sm-6">
                                            <input type="tel" class="form-control rounded-2" id="number" name="number"
                                                placeholder="Phone Number" />
                                            <i class="far fa-phone"></i>
                                        </div>



                                        <div class="form-group col-lg-8 col-sm-6">
                                            <input type="text" class="form-control rounded-2" id="message" name="message"
                                                placeholder="Message" />
                                            <i class="far fa-comment"></i>
                                        </div>
                                        <div class="form-btn col-lg-4 col-sm-6">
                                            <button class="th-btn style5 rounded-2 w-100">Send Message<i
                                                class="fa-solid fa-arrow-right ms-2"></i></button>
                                            <Link to="/Idgnew/contactus" className="th-btn style5 rounded-2 w-100">
                                                Send Message<i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <p class="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </section> */}
             <section style={{ marginTop: "80px" }}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-4 text-center text-xl-start">
                            <div class="title-area mb-25">
                                <span class="sub-title" style={{ fontFamily:'helvetica',color:'#F0B323'}}>WHY CHOOSE US</span>
                                <h2 class="sec-title" style={{ fontFamily:'helvetica',color:'#0f1138' }}>Elevating Frozen Food Excellence for Over Two Centuries</h2>
                            </div>
                            <p class="mt-n2 mb-30" style={{ fontFamily:'helvetica' }}>Choose Icedream Global for a legacy of over 200 years, delivering excellence in frozen
                                food products with a steadfast commitment to quality, innovation, and customer satisfaction.</p>
                            {/* <a href="#" class="th-btn rounded-2">ABOUT MORE<i
                                class="fa-solid fa-arrow-right ms-2"></i></a> */}
                            <Link to="/about" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:"#F0B323" }}>
                                ABOUT MORE
                                <i className="fa-solid fa-arrow-right ms-2"></i>
                            </Link>
                        </div>
                        <div class="col-xl-8 mt-40 mt-xl-0">
                            <div class="row gy-4 justify-content-center">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="why-feature" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                        <div class="why-feature_icon">
                                            <img src="assets/img/update_2/icon/qahome111.svg" alt="icon" />
                                        </div>
                                        <h3 class="why-feature_title" style={{ fontFamily:'helvetica',color:'#0f1138' }}>Quality Assurance</h3>
                                        <p class="why-feature_text" style={{ fontFamily:'helvetica' }}>
                                            Our state-of-the-art factory ensures that each product meets the highest standards of safety, reliability, and quality.</p>

                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="why-feature">
                                        <div class="why-feature_icon">
                                            <img src="assets/img/update_2/icon/custcentric111.svg" alt="icon" />
                                        </div>
                                        <h3 class="why-feature_title" style={{ fontFamily:'helvetica',color:'#0f1138' }}>Customer-Centric Approach</h3>
                                        <p class="why-feature_text" style={{ fontFamily:'helvetica' }}>
                                            With a focus on building long-term relationships, we prioritize customer satisfaction above all.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="why-feature">
                                        <div class="why-feature_icon">
                                            <img src="assets/img/update_2/icon/expertinfra111.svg" alt="icon" />
                                        </div>
                                        <h3 class="why-feature_title" style={{ fontFamily:'helvetica',color:'#0f1138' }}>Expertise & Infrastructure</h3>
                                        <p class="why-feature_text" style={{ fontFamily:'helvetica' }}>With over 200 years of experience in the food and FMCG
                                            industry through GRKC Group.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            {/* <section className="overflow-hidden space">

                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="pe-xxl-5">
                                <div className="title-area mb-0 text-center text-xl-start">
                                    <span className="sub-title">Testimonials</span>
                                    <h2 className="sec-title">What customer saying About Services</h2>
                                </div>
                            </div>
                            <div className="testi-card-slide">
                                <div className="row th-carousel number-dots" data-slide-show="1" data-lg-slide-show="2"
                                    data-md-slide-show="1" data-dots="true" data-xl-dots="true" data-ml-dots="true"
                                    data-lg-dots="true" data-md-dots="true">
                                        
                                    <Slider {...settingstestimonal}>
                                        <div>
                                            <p>
                                              
                                                <div class="testi-block">
                                                    <div class="testi-block_shape">
                                                        <img src={testiblock_bg} alt="BG" />
                                                    </div>
                                                    <div class="testi-block_profile">
                                                        <div class="testi-block_avater">
                                                            <img src={testi_4_1} alt="Avater" />
                                                            <div class="testi-block_quote">
                                                                <img src={quote_1} alt="Icon" />
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <div class="testi-block_review">
                                                                <i class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i><i
                                                                                class="fa-sharp fa-solid fa-star"></i>
                                                            </div>
                                                            <h3 class="testi-block_name">Rayan Kook</h3>
                                                            <span class="testi-block_desig">NYC, USA</span>
                                                        </div>
                                                    </div>
                                                    <p class="testi-block_text">As a passionate restaurant owner, ensuring that our dishes delight our customers is paramount. IDG has been our steadfast partner for sourcing top-quality Indian shrimps, a key ingredient in many of our signature dishes. Their consistent excellence in providing shrimps of perfect size, flavor, and texture has been instrumental in maintaining our reputation for culinary excellence.</p>
                                                </div>
                                            </p>


                                        </div>
                                        <div>
                                            <p>
                                              
                                                <div class="testi-block">
                                                    <div class="testi-block_shape">
                                                        <img src={testiblock_bg} alt="BG" />
                                                    </div>
                                                    <div class="testi-block_profile">
                                                        <div class="testi-block_avater">
                                                            <img src={testi_4_2} alt="Avater" />
                                                            <div class="testi-block_quote">
                                                                <img src={quote_1} alt="Icon" />
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <div class="testi-block_review">
                                                                <i class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i><i
                                                                                class="fa-sharp fa-solid fa-star"></i>
                                                            </div>
                                                            <h3 class="testi-block_name">Michel Clark</h3>
                                                            <span class="testi-block_desig">DYM, USA</span>
                                                        </div>
                                                    </div>
                                                    <p class="testi-block_text"> IDG's commitment to sustainable practices is what sets them apart in the competitive seafood market. As a consumer conscious of environmental impact, knowing that I'm purchasing ethically sourced Indian shrimps from IDG gives me peace of mind.</p>

                                                </div>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                               
                                                <div class="testi-block">
                                                    <div class="testi-block_shape">
                                                        <img src={testiblock_bg} alt="BG" />
                                                    </div>
                                                    <div class="testi-block_profile">
                                                        <div class="testi-block_avater">
                                                            <img src={testi_4_3} alt="Avater" />
                                                            <div class="testi-block_quote">
                                                                <img src={quote_1} alt="Icon" />
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <div class="testi-block_review">
                                                                <i class="fa-sharp fa-solid fa-star"></i><i
                                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                                            class="fa-sharp fa-solid fa-star"></i><i
                                                                                class="fa-sharp fa-solid fa-star"></i>
                                                            </div>
                                                            <h3 class="testi-block_name">Rosse Mons</h3>
                                                            <span class="testi-block_desig">DO, USA</span>
                                                        </div>
                                                    </div>
                                                    <p class="testi-block_text">Commitment to quality, reliability, and sustainability is unmatched. We've been consistently impressed with the freshness and taste of their Indian shrimps, and their seamless logistics ensure timely deliveries every time.</p>
                                                </div>
                                            </p>
                                        </div>
                                    </Slider>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 mt- mt-xl-0">
                            <div class="testi-card-img">
                                <div class="img1">
                                    <img src={testicardimg} alt="Image" />
                                </div>
                                <div class="shape1">
                                    <img src={testicardshape} alt="Image" />
                                </div>
                                <div class="shape2">
                                    <img src="assets/img/update_2/normal/testi-card_shape_2.png" alt="Image" />
                                </div>
                                <div class="shape3">
                                    <img src="assets/img/update_2/normal/testi-card_shape_3.png" alt="Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <br />
            <br />
            <br />
            <br />
            <br />
            <section className="overflow-hidden space" style={{ marginTop: '-50px', padding: '20px' }}>
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename" style={{ fontFamily:'helvetica',color:'#0f1138'}}>OUR CERTIFICATIONS</h1>
                </div>
                <Slider {...settingstwice}>
                    <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
                        <img className="gray" src={certifications6} alt="Brand Logo" />
                    </div>
                    <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
                        <img className="gray" src={certifications4} alt="Brand Logo" />
                    </div>
                </Slider>

            </section>
            <br /><br /><br /><br /><br />
            <Footer />
            
        </div>
    );
};

export default Homepage;
