
// import React, { useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import idgcompresschart from "../assets/img/IDG dirgram compress.jpg";
import idgchartx1 from "../assets/img/idgchartx1.jpg";
import collagepicupdated from "../assets/img/collagepicupdated.jpg";
import collagepicx1 from "../assets/img/collagepicx1.jpg";
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './footer';


const Quality = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? 'var(--smoke-color2)' : 'var(--smoke-color2)', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };



    return (
        <div>
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${breadcumb_bg_2})` }}> */}
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${breadcumb_bg_2})` }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Quality</h1>
                        <ul class="breadcumb-menu">
                            <li><Link to="/Idgnew/home">Home</Link></li>
                            <li>Quality</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className="d-none d-md-block hidearrow">

                <div className="infrastructure-name">
                    <div style={{ marginTop: "-40px" }}>
                        <ul style={{ listStyleType: 'none', padding: 20 }}>
                            <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white'}}>QUALITY</h1></li>
                            <li>
                                <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Quality</span></span>
                            </li>
                        </ul>
                    </div>
                </div>




                <img
                    width="2000"
                    height="2000"
                    src={bg22}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'right',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                />

                {/* <img
                    width="2000"
                    height="700"
                    src={banner2}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'left',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                /> */}



            </div>
            <br /><br />
            <div classNameName="d-none d-md-block">
                <div className="title-area text-center">
                    <br />
                    <h2 className="sec-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>OUR FACTORY</h2>
                </div>
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>
                    <div class='container'>
                        <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                            <img
                                width="2000"
                                // height="1000px"
                                src={collagepicx1}
                                className="ls-l ls-img-layer factory"
                                alt="image"
                                decoding="async"
                                style={{
                                    padding: '20px',
                                    fontSize: '36px',
                                    height: "1000px",
                                    color: '#000',
                                    stroke: '#000',
                                    strokeWidth: '0px',
                                    textAlign: 'right',
                                    fontStyle: 'normal',
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: '400',
                                    letterSpacing: '0px',
                                    backgroundPosition: '0% 0%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundClip: 'border-box',
                                    overflow: 'visible',
                                    left: '0px',
                                    top: '107px',
                                    WebkitBackgroundClip: 'border-box'
                                }}
                                data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div classNameName="d-none d-md-block">
                <div className="title-area text-center">
                    <br />
                    <h2 className="sec-title" style={{ fontFamily:'helvetica',color:'#0f1138',textTransform: 'uppercase' }}>Good Manufacturing Practices</h2>
                  
                </div>
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>
                    <div class='container'>
                        <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                            <img
                                width="2000"
                                height="700px"
                                src={idgchartx1}
                                className="ls-l ls-img-layer factory"
                                alt="image"
                                decoding="async"
                                style={{
                                    fontSize: '36px',
                                    height: "700px",
                                    color: '#000',
                                    stroke: '#000',
                                    strokeWidth: '0px',
                                    textAlign: 'right',
                                    fontStyle: 'normal',
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: '400',
                                    letterSpacing: '0px',
                                    backgroundPosition: '0% 0%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundClip: 'border-box',
                                    overflow: 'visible',
                                    left: '0px',
                                    top: '107px',
                                    WebkitBackgroundClip: 'border-box'
                                }}
                                data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                            />
                        </div></div></div>
            </div>
            <br /><br />
            <section class="mt-5">
                <div class="container">
                    <div className="title-area text-center">
                        <span className="sub-title" style={{ fontFamily:'helvetica',color:'#F0B323'}}>WHY CHOOSE US</span>
                        <h2 className="sec-title" style={{ fontFamily:'helvetica',color:'#0f1138',textTransform: 'uppercase' }}>Commitment to Excellence</h2>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-xl-4 col-md-6">
                            <div className="why-list ">

                                <div className="media-body" style={{ textAlign: 'left' }}>
                                    <h4 className="box-title start-left" style={{ fontFamily:'helvetica',color:'#0f1138'}}>
                                        Location Of Plants - Potato And Shrimp</h4>
                                    <p className="why-list_text" style={{ fontFamily:'helvetica' }}>
                                    Icedream Global production facilities boast state-of-the-art technology for both potato and shrimp processing.</p>
                                </div>
                            </div>
                            <div className="why-list ">

                                <div className="media-body" style={{ textAlign: 'left' }}>
                                    <h4 className="box-title start-left" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Contract Farming</h4>
                                    <p className="why-list_text" style={{ fontFamily:'helvetica' }}>
                                        Through our contract farming initiatives, we collaborate with farmers to ensure a
                                        consistent and reliable supply of high-quality raw materials.</p>
                                </div>
                            </div>
                            <div className="why-list ">

                                <div className="media-body" style={{ textAlign: 'left' }}>
                                    <h4 className="box-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Safety And Hygiene Protocols</h4>
                                    <p className="why-list_text text-left" style={{ fontFamily:'helvetica' }}>Stringent safety and hygiene protocols are implemented
                                        throughout our production process to guarantee the utmost safety for our consumers</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 d-none d-xl-block">
                            <div className="text-center">
                                <img style={{ height: '300px' }} src="assets/img/update_2/normal/image_menu_2.png" alt="About" />
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div className="why-list">

                                <div className="media-body">
                                    <h4 className="box-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Cold Storage Warehouses</h4>
                                    <p className="why-list_text" style={{ fontFamily:'helvetica' }}>Our cold storage warehouses ensure optimal conditions for
                                        preserving the freshness and quality of our products.</p>
                                </div>
                            </div>
                            <div className="why-list">

                                <div className="media-body">
                                    <h3 className="box-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Advanced Freezing Technology</h3>
                                    <p className="why-list_text" style={{ fontFamily:'helvetica' }}>Utilizing advanced freezing technology, we maintain the integrity
                                        of our products while extending their shelf life.</p>
                                </div>
                            </div>
                            <div className="why-list">

                                <div className="media-body">
                                    <h3 className="box-title" style={{ fontFamily:'helvetica',color:'#0f1138'}}>Compliance With Industry Standards</h3>
                                    <p className="why-list_text" style={{ fontFamily:'helvetica' }}>We adhere strictly to industry standards, ensuring that our
                                        products meet the highest quality and safety requirements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <h2 className="section-heading">
                Culture and Sea Catch
            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                               
                                Our commitment to quality extends to our freezing methods, offering shrimps frozen as Blocks, Semi-IQF, or IQF, preserving their freshness and flavor. With our expertise in shrimp processing, we can also provide shrimps at various stages of value addition, giving you the flexibility to customize your order to suit your culinary needs.
                            </p>
                            <p class="section-subheading">
                                Discover the difference in taste and quality with our premium shrimp selection. Place your order today and elevate your dining experience with our exceptional seafood offerings.
                            </p>
                            <p>
                                <ul class="list-group">
                                    <li class="list-group-item"><span class="dot"></span>VANNAMEI (Litopenaeus Vannamei)
                                        <span class="badge"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Black Tiger (Penaeus Monodon), White and Sea Tiger etc. HLSO, Easy Peel, PDTO and PD etc.
                                        <span class="badge"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>HLSO, Easy Peel, PDTO and PD etc.
                                        <span class="badge"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>IQF Product- HLSO, Easy Peel, PDTO and PD etc.
                                        <span class="badge"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>

                                </ul>

                            </p>

                        </div>
                    </div>
                </div>
            </div> */}





            {/* <section class="bg-white mt-5 mb-5">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-5">
                            <div class="about-card_img">
                                <img class="w-100" src={team_details} alt="team image" />
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <div class="about-card">
                                <h2 className="about-card_title">Culture and Sea Catch</h2>
                                <p class="about-card_text">We take pride in our ability to deliver farm raised and sea-caught
                                    shrimp tailored to individual customer's requirements. We can provide the quality, sizes,
                                    grades, and assortments you need in various styles of freezing or at various stages of value
                                    addition. We provide all shrimps in - Head On, Headless Shell On, Peeled and Deveiled,
                                    Peeled Undeveined, Easy Peel forms Frozen as - Blocks, Semi-IQF or IQF. </p>
                                <ul>
                                    <li>
                                    VANNAMEI (Litopenaeus Vannamei)
                                    </li>
                                    <li>
                                        Black Tiger (Penaeus Monodon), White and Sea Tiger etc.
                                        HLSO, Easy Peel, PDTO and PD etc.
                                    </li>
                                    <li>
                                        HLSO, Easy Peel, PDTO and PD etc.

                                    </li>
                                    <li>
                                        IQF Product- HLSO, Easy Peel, PDTO and PD etc.

                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <div class="row gy-40">

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                       
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp2} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp2.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                      
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                          
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo1} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo1.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                           
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp1} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp1.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                            
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo3} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo3.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                           
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo4} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo4.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                            
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo5} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo5.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>


                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                        
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                           
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp3} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp3.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                            
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={shrimp4} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/shrimp4.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                       
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="th-menu">
                        <div class="" >
                           
                            <div class="gallery-card style2 mb-0">
                                <div class="gallery-img">
                                    <img style={{ borderRadius: '3%', width: '400px', height: '300px' }} src={torpedo6} alt="gallery image" />
                                </div>
                                <div class="gallery-content">
                                    <h2 class="gallery-title box-title">Sea food</h2>
                                    <a href="assets/img/update_2/normal/torpedo6.jpeg" class="icon-btn popup-image"><i
                                        class="fas fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="th-menu-content">
                            <h3 class="th-menu_title"><a href="shop-details.html">Frozen pud Shrimp</a></h3>
                            <p class="th-menu_desc">Freesh Sea Food</p>

                        </div>
                        
                    </div>
                </div>
            </div> */}
            <br /><br />
            {/* <section class="pt-5 mb-5 bg-smoke2" style={{ marginBottom: "10px" }}>
                <div class="container">
                    <div class="title-area text-center">

                        <h2 class="sec-title">Our Others <span class="font-style text-theme">Products</span></h2>
                        <p class="sec-text ms-auto me-auto">Objectively pontificate quality models before intuitive information.
                            Dramatically recaptiualize multifunctional materials.</p>
                    </div>
                 
                    <div class="mt-5">
                        <div class="container">
                          
                            <div class="row th-carousel" data-slide-show="6" data-ml-slide-show="5" data-lg-slide-show="4"
                                data-md-slide-show="3" data-sm-slide-show="2" data-xs-slide-show="2">


                                <Slider {...settingsscroll}>


                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                        <div class="category-border">
                                            <div class="category-border_img">
                                                <Link to="/Idgnew/product" className="">
                                                    <img src={category_6_2} alt="Image" />
                                                </Link>
                                            </div>

                                            <h3 class="team-title box-title"><a href="team-details.html">Frozen pud Shrimp</a></h3>
                                            <span class="team-desig">Fresh Sea Food</span>
                                            
                                            &nbsp; <Link to="/Idgnew/product" className="">
                                                Read more
                                                <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                        <div class="category-border">
                                            <div class="category-border_img">

                                                <Link to="/Idgnew/product" className="">
                                                    <img src={category_6_3} alt="Image" />
                                                </Link>
                                            </div>
                                            <h3 class="team-title box-title"><a href="team-details.html">Frozen pud Shrimp</a></h3>
                                            <span class="team-desig">Fresh Sea Food</span>
                                          
                                            &nbsp; <Link to="/Idgnew/product" className="">
                                                Read more
                                                <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                        <div class="category-border">
                                            <div class="category-border_img">
                                                <Link to="/Idgnew/product" className="">
                                                    <img src={category_6_4} alt="Image" />
                                                </Link>
                                            </div>

                                            <h3 class="team-title box-title"><a href="team-details.html">Frozen pud Shrimp</a></h3>
                                            <span class="team-desig">Fresh Sea Food</span>
                                          
                                            &nbsp; <Link to="/Idgnew/product" className="">
                                                Read more
                                                <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                        <div class="category-border">
                                            <div class="category-border_img">
                                                <Link to="/Idgnew/product" className="">
                                                    <img src={category_6_5} alt="Image" />
                                                </Link>
                                            </div>
                                            <h3 class="team-title box-title"><a href="team-details.html">Frozen pud Shrimp</a></h3>
                                            <span class="team-desig">Fresh Sea Food</span>
                                           
                                            &nbsp; <Link to="/Idgnew/product" className="">
                                                Read more
                                                <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                    
                                        <div class="category-border">
                                            <div class="category-border_img">
                                                <Link to="/Idgnew/product" className="">
                                                    <img src={category_6_7} alt="Image" />
                                                </Link>
                                            </div>
                                            <h3 class="team-title box-title"><a href="team-details.html">Frozen pud Shrimp</a></h3>
                                            <span class="team-desig">Fresh Sea Food</span>
                                           
                                            &nbsp; <Link to="/Idgnew/product" className="">
                                                Read more
                                                <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </section> */}
            {/* <section className="mt-5">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">Why Choose Us</span>
                        <h2 className="sec-title">Commitment to Excellence</h2>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid orange', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'orange', fontSize: '1.1em' }}>Personnel Training</p>

                                    <p className="why-list_text">Periodic training by Subject Matter Experts.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid #9d21a5', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: '#9d21a5;', fontSize: '1.1em' }}>Risk Management</p>

                                    <p className="why-list_text">Hazard analysis and critical control points.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid lightgreen', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'lightgreen', fontSize: '1.1em' }}>Continuous Improvements</p>

                                    <p className="why-list_text">Deriving higher efficiencies across all processes</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-none d-xl-block">
                            <div className="text-center" style={{ borderRadius: '50%', width: '250px', height: '250px', margin: '0 auto 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '24px', fontWeight: 'bold', boxShadow: '#b3220b45 0px 0px 0px 4px, rgba(255, 0, 0, 0) 0px 0px 0px 5px, #2f2fad00 0px 0px 0px 6px, orange 0px 0px 0px 12px, #80008000 0px 0px 0px 10px, yellow 0px 0px 0px 14px, orange 0px 0px 0px 14px', backgroundColor: 'lightblue' }}>
                                <div style={{ backgroundColor: 'white', borderRadius: '50%', width: '80%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>GMP</div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid blue', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'blue', fontSize: '1.2em' }}>Maintenance & Sanitation</p>

                                    <p className="why-list_text">Preventive maintenance of equipment and hygiene control at all touchpoints.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid purple', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'purple', fontSize: '1.3em' }}>Quality Management System(TBQMS)</p>

                                    <p className="why-list_text">Food quality managed from sourcing to dispatch</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid green', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'green', fontSize: '1.4em' }}>Site & Facility Management</p>

                                    <p className="why-list_text">Governance of storage,services,utilities,equipment,etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid red', borderRadius: '50px', padding: '20px' }}>
                                    <p style={{ color: 'red', fontSize: '1.5em' }}>Product Safety</p>

                                    <p className="why-list_text">Ensuring compliance of all food safety standards</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="mt-5">
                
                <div className="container-fluid" style={{background:"black"}}>
                    <div className="title-area text-center">
                    <br/>
                        <span className="sub-title">Why Choose Us</span>
                        <h2 className="sec-title" style={{color:"white"}}>Our Commitment to Quality</h2>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid orange', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'orange', fontSize: '1.1em' }}>Personnel Training</p>
                                    <p className="why-list_text">Periodic training conducted by Subject Matter Experts.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid #9d21a5', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: '#9d21a5', fontSize: '1.1em' }}>Risk Management</p>
                                    <p className="why-list_text">Hazard analysis, critical control points, and risk assessment.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid lightgreen', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'lightgreen', fontSize: '1.1em' }}>Continuous Improvements</p>
                                    <p className="why-list_text">Deriving higher efficiencies and optimizations across all processes</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-none d-xl-block">
                            <div className="text-center" style={{ borderRadius: '50%', width: '250px', height: '250px', margin: '0 auto 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '24px', fontWeight: 'bold', boxShadow: '#b3220b45 0px 0px 0px 4px, rgba(255, 0, 0, 0) 0px 0px 0px 5px, #2f2fad00 0px 0px 0px 6px, orange 0px 0px 0px 12px, #80008000 0px 0px 0px 10px, yellow 0px 0px 0px 14px, orange 0px 0px 0px 14px', backgroundColor: 'lightblue' }}>
                                <div style={{ backgroundColor: 'white', borderRadius: '50%', width: '80%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>GMP</div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid blue', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'blue', fontSize: '1.2em' }}>Maintenance & Sanitation</p>
                                    <p className="why-list_text">Preventive maintenance of equipment and hygiene control at all touchpoints.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid purple', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'purple', fontSize: '1.3em' }}>Quality Management System(TBQMS)</p>
                                    <p className="why-list_text">Food quality managed meticulously from sourcing to dispatch.</p>
                                </div>
                            </div>
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid green', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'green', fontSize: '1.4em' }}>Site & Facility Management</p>
                                    <p className="why-list_text">Governance of storage,services,utilities,equipment,etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="why-list">
                                <div className="media-body" style={{ textAlign: 'center', border: '1px solid red', borderRadius: '50px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <p style={{ color: 'red', fontSize: '1.5em' }}>Product Safety</p>
                                    <p className="why-list_text">Ensuring strict compliance of all food safety standards.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
            </section> */}
            <br /> <br /> <br />
            <Footer />
        </div>
    );
};

export default Quality;
