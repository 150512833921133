import React from 'react';
import bg22 from "../assets/img/bg22.png";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Footer from './footer';

const Areasalesmanager = () => {
    const handleClick = () => {
        window.location.href = '/about';
        window.scrollTo(0, 0);
    };
    const handleClickTwo = () => {
        window.location.href = '/home';
        window.scrollTo(0, 0);
    };
    return (
        <div>
            {/* <div className="position-relative">
                <div className="position-absolute top-0 start-0 translate-middle p-3 careerText">
                    <span style={{ color: 'white', fontSize: '48px' }}>Careers</span>
                    <br />
                    <br />
                  
                    <Link to="/Idgnew/home" onClick={handleClickTwo} style={{ color: 'white', padding: 4 }}><span className="homeclass">Home</span> &nbsp;</Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> <span style={{ color: 'red' }}>Careers</span>
                </div>
                <img
                    width="2000"
                    height="2000"
                    src={bg22}
                    className="ls-l ls-img-layer"
                    alt="image"
                    decoding="async"
                    style={{
                        fontSize: '36px',
                        color: '#000',
                        stroke: '#000',
                        strokeWidth: '0px',
                        textAlign: 'right',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        backgroundPosition: '0% 0%',
                        backgroundRepeat: 'no-repeat',
                        backgroundClip: 'border-box',
                        overflow: 'visible',
                        left: '0px',
                        top: '107px',
                        WebkitBackgroundClip: 'border-box'
                    }}
                    data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                />
            </div> */}
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>


                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white' }}>CAREERS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323' }}>Careers</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height="435"
                            src={bg22}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                               
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />

                    </div>



                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-area mb-30 text-center text-xl-start">
                            <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Key responsibility</h4>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>As Sales Person , you are responsible for customer service and acquiring new customers. You will also continue to expand sales and revenue in the region.</li>
                            <li>You are responsible for acquiring new customers from end users and wholesalers.</li>
                            <li>You will be responsible for supporting and expanding the sales area in accordance with the sales strategy.</li>
                            <li>You are also responsible for implementing central agreements with individual customers in the assigned region.
                                <br />
                                You work with regional wholesalers in coordination with the regional sales manager and colleagues.
                            </li>
                        </ul>

                        <div className="title-area mb-30 text-center text-xl-start">
                            <p className="sec-title" style={{ fontFamily:'helvetica' }}>Qualification :</p>
                        </div>
                        <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                            <li>Minimum Graduate or Equivalent to graduation</li>
                            <li>MBA is preferred but not mandatory</li>
                            <li>Experience with frozen quick food is compulsory</li>
                        </ul>

                        <p className="sec-title" style={{ fontFamily:'helvetica' }}>You have excellent knowledge of the food service business.
                            <br />
                            You have training in the catering or food sector and good commercial or business knowledge.
                            <br />
                            You have experience in the catering industry, in food service field sales, or in wholesale (food sector).
                            <br />
                            You are self-organized, strong in setting priorities and take your</p>
                    </div>
                    {/* <div className="col-lg-6">
                        <form className="border p-4">
                            <h2 className="mb-4">Apply for this position</h2>
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Full Name *</label>
                                <input type="text" className="form-control" id="fullName" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email *</label>
                                <input type="email" className="form-control" id="email" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone *</label>
                                <input type="tel" className="form-control" id="phone" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="coverLetter" className="form-label">Cover Letter *</label>
                                <textarea className="form-control" id="coverLetter" rows="4" required></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="resume" className="form-label">Upload CV/Resume *</label>
                                <input type="file" className="form-control" id="resume" accept=".pdf,.doc,.docx" required />
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="agree" required />
                                <label className="form-check-label" htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="th-btn rounded-2">Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                            </div>
                        </form>
                    </div> */}
                     <div className="row">
                     <h2 className="mb-4 text-center" style={{ fontFamily:'helvetica' }}>Apply for this position</h2>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                {/* <h2 className="mb-4">Apply for this position</h2> */}
                                <div className="mb-3">
                                    <label htmlFor="fullName" className="form-label" style={{ fontFamily:'helvetica' }}>Full Name *</label>
                                    <input type="text" className="form-control" style={{ fontFamily:'helvetica' }} id="fullName" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label" style={{ fontFamily:'helvetica' }}>Email *</label>
                                    <input type="email" className="form-control" style={{ fontFamily:'helvetica' }} id="email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label" style={{ fontFamily:'helvetica' }}>Phone *</label>
                                    <input type="tel" className="form-control" style={{ fontFamily:'helvetica' }} id="phone" required />
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <form className="border p-4">
                                <div className="mb-3">
                                    <label htmlFor="coverLetter" className="form-label" style={{ fontFamily:'helvetica' }}>Cover Letter *</label>
                                    <textarea className="form-control" id="coverLetter" style={{ fontFamily:'helvetica' }} rows="4" required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="resume" className="form-label" style={{ fontFamily:'helvetica' }}>Upload CV/Resume *</label>
                                    <input type="file" className="form-control" style={{ fontFamily:'helvetica' }} id="resume" accept=".pdf,.doc,.docx" required />
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="agree" required  style={{ fontFamily:'helvetica' }}/>
                                    <label className="form-check-label" style={{ fontFamily:'helvetica' }} htmlFor="agree">By using this form you agree with the storage and handling of your data by this website. *</label>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="th-btn rounded-2" style={{ fontFamily:'helvetica',background:'#F0B323' }}>Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br />  <br />  <br />  <br />
            <Footer />
        </div>
    );
};

export default Areasalesmanager;
