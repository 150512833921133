// import React from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import patwedges from "../assets/img/update_2/normal/patwedges.png";
import pattyburger11 from "../assets/img/update_2/normal/pattyburger11.jpg";
import hashbrown1 from "../assets/img/update_2/normal/hashbrown1.jpg";
import hashbrown12 from "../assets/img/update_2/normal/hashbrown12.png";
import herbchillyburgerpatty from "../assets/img/update_2/normal/herbchillyburgerpatty.png";
import alootikki from "../assets/img/update_2/normal/alootikki01.png";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import potatoban1 from "../assets/img/potatoban1.png";
import Footer from './footer';

const Potatoes = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    return (
        <div>
            {/* <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${breadcumb_bg_2})` }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Products</h1>
                        <ul class="breadcumb-menu">
                            <li><Link to="/Idgnew/home">Home</Link></li>
                            <li>Products</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>
                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica',color:'white'}}>PRODUCTS</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323'}}>Potatoes</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img
                            width="2000"
                            height=""
                            src={potatoban1}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                                height: "",
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />
                    </div>
                </div>
            </div>

            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Herb and Chilli Burger Patty
            </h2>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-0 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${herbchillyburgerpatty})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'
                                }}
                            ></div>
                        </div>
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Consistent quality burgers, anytime, every time</li>
                                <li>Consistent shape and piece weight which ensures zero wastage and enhances cost control</li>
                                <li>Create signature burgers with your choice of sauces and toppings</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                    Deep Fry/Shallow Fry: Preheat oil to 175°C. Deep fry your  Burger Patty for about 3 minutes to a light golden colour
                                </li>
                                <li>
                                    Air Fry: Preheat the air fryer to 200°C. Put your  Burger Patty in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between

                                </li>
                                <li>
                                    Bake: Preheat the oven to 220°C. Arrange your Burger patty in a single layer on a baking tray and bake for approx. 12-15 minutes to a light golden colour
                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 Months</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Ingredients</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Potatoes, Refined Vegetable Oil, Corn Flour, Green Chilli Paste, Rice Flakes, Garlic Paste, Ginger Paste, Iodised Salt, Dehydrated White Onion Powder, Red Chilli Flakes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
          
            <br />
            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Potato Burger Patty
            </h2>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Consistent quality burgers, anytime, every time</li>
                                <li>Consistent shape and piece weight which ensures zero wastage and enhances cost control</li>
                                <li>Create signature burgers with your choice of sauces and toppings</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                    Deep Fry/Shallow Fry: Preheat oil to 175°C. Deep fry your  Burger Patty for about 3 minutes to a light golden colour
                                </li>
                                <li>
                                    Air Fry: Preheat the air fryer to 200°C. Put your  Burger Patty in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between

                                </li>
                                <li>
                                    Bake: Preheat the oven to 220°C. Arrange your Burger patty in a single layer on a baking tray and bake for approx. 12-15 minutes to a light golden colour
                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 Months</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Ingredients</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Potatoes, Refined Vegetable Oil, Corn Flour, Green Chilli Paste, Rice Flakes, Garlic Paste, Ginger Paste, Iodised Salt, Dehydrated White Onion Powder, Red Chilli Flakes</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-0 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${pattyburger11})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Potato Wedges
            </h2>
            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-4 mt-xl-2 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${patwedges})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'

                                }}
                            ></div>
                        </div>
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>


                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Exceptional taste and great plate coverage</li>
                                <li>Skin-on wedge enhances flavour and retains more fiber</li>
                                <li>Versatile to use as a side order or main meal upgrade</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                    Deep Fry/Shallow Fry: Preheat oil (recommended maximum temperature: 175°C). Deep fry half a basket of  Potato Wedges for about 3 minutes to a light golden colour
                                </li>
                                <li>
                                    Air Fry: Preheat the air fryer to 200°C. Spread your Potato Wedges in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between

                                </li>
                                <li>
                                    Bake: Preheat the oven to 220°C. Arrange your Potato Wedges in a single layer on a lightly greased baking tray and bake for approximately 12-15 minutes to a light golden colour
                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 Months</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Ingredients</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Potatoes, Refined Palmolein Oil, Iodized salt, Corn Flour, Iodized Salt, Red Chilli Flakes, Dehydrated White Onion Powder</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Hash Brown
            </h2>

            <div className="about-sec overflow-hidden pb-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                       
                        <div className="col-xl-6" style={{ marginTop: '66px' }}>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Benefits</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Great taste and texture with a soft potato inner side and crisp external bite</li>
                                <li>A perfect accompaniment for all-day breakfasts and Hi- Tea</li>
                                <li>Multiple garnishing options</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Preparation</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>
                                    Deep Fry/Shallow Fry: Preheat oil to 175°C. Cook half a basket of HyFun Hash Browns for about 3 minutes to a light golden colour
                                </li>
                                <li>
                                    Air Fry: Preheat the air fryer to 200°C. Spread your HyFun Hash Browns in a single layer on a frying tray and air fry for approximately 12-15 minutes till light golden colour. Shake couple of times in between
                                </li>
                                <li>
                                    Bake: Preheat the oven to 220°C. Arrange your HyFun Hash Browns in a single layer on a lightly greased baking tray and bake for approximately 12-15 minutes to a light golden colour

                                </li>
                            </ul>

                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Storage</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Keep frozen at -18°C or below</li>
                                <li>Do not refreeze after thawing</li>
                                <li>Shelf life 24 Months</li>
                            </ul>
                            <div className="title-area mb-30 text-center text-xl-start">
                                <h4 className="sec-title" style={{ fontFamily:'helvetica' }}>Ingredients</h4>
                            </div>
                            <ul className="mt-n2 mb-25 text-center text-xl-start" style={{ fontFamily:'helvetica' }}>
                                <li>Potato Shreds, Corn Flour, Refined Palmolein Oil, Onion Powder & Salt.</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 mt-4 mt-xl-0 d-flex justify-content-center align-items-center">
                            <div
                                className="testi-card-img"
                                style={{
                                    backgroundImage: `url(${hashbrown12})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '600px',
                                    height: '500px',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                    borderRadius: '10px',
                                    marginLeft: '-20px'
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="mt-5">
                <div class="container">
                    <div class="title-area text-center">
                        <span class="sub-title">Best Sea food for family</span>
                        <h2 class="sec-title">Choose Our best Product</h2>
                    </div>
                    <div class="row th-carousel" data-slide-show="6" data-ml-slide-show="5" data-lg-slide-show="4"
                        data-md-slide-show="3" data-sm-slide-show="2" data-xs-slide-show="2">


                        <Slider {...settingsscroll}>


                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato1} alt="Image" />
                                        </Link>
                                    </div>


                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">

                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato2} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato3} alt="Image" />
                                        </Link>
                                    </div>


                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato4} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato5} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato6} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato7} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato8} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato9} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <Link to="/Idgnew/product" className="">
                                            <img src={potato10} alt="Image" />
                                        </Link>
                                    </div>

                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </div> */}
            <br />
            <Footer />
        </div>
    );
};

export default Potatoes;
