// import React from 'react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import breadcumb_bg_2 from "../assets/img/update_2/bg/breadcumb_bg_2.jpg";
import bannerbg from "../assets/img/update_2/bg/bannerbg.png";
import banabt1 from "../assets/img/banabt1.png";
import abtban2 from "../assets/img/abtban2.png";
import seasmithyumway1 from "../assets/img/seasmithyumway1.png";
import blueyumwayseasmith from "../assets/img/blueyumwayseasmith.png";

import bannerbgfrozenpat from "../assets/img/update_2/bg/bannerbgfrozentseapat.png";
import { Link } from 'react-router-dom';

import cta_bg_3 from '../assets/img/update_2/bg/cta_bg_3.jpg'
import year_title_bg from '../assets/img/update_2/bg/year_title_bg.svg'
import bgbanner2 from '../assets/img/update_2/bg/bannerbg2.png'
import testi_bg_4 from '../assets/img/update_2/bg/testi_bg_4.png'
import tomato_6 from '../assets/img/update_2/shape/tomato_6.png'
import french_fry from '../assets/img/update_2/shape/jinga1.png'
import logo from "../assests/img/logo-white.png";
import logoidg from "../assets/img/logoidg.png";
import updatedlogo from "../assets/img/updatedlogo.png";
import finalLogo from "../assets/img/finalLogo.png";
import footer_bg_5 from "../assets/img/update_2/bg/feature_bg_5.png";
import feature_bg_51 from "../assets/img/update_2/bg/feature_bg_51.jpg";
import ourglobalpresence from "../assets/img/OUR GLOBAL PRESENCE.png";
import map1 from "../assets/img/map1.jpg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bg01 from "../assets/img/bg01.png";
import bg02 from "../assets/img/bg02.png";

import about_6_1 from "../assets/img/update_2/normal/about_6_1.png";
import about_6_2 from "../assets/img/update_2/normal/about_6_2.png";
import dishround from "../assets/img/update_2/normal/dishround.png";
import roundpotato from "../assets/img/update_2/normal/roundedpotato.png";
import why_feature_1 from "../assets/img/update_2/icon/why_feature_1.svg";
import why_feature_4 from "../assets/img/update_2/icon/why_feature_4.svg";
import why_feature_2 from "../assets/img/update_2/icon/why_feature_2.svg";
import why_feature_3 from "../assets/img/update_2/icon/why_feature_3.svg";
import testiblock_bg from "../assets/img/update_2/bg/testi-block_bg.png";

import cherry_1 from "../assets/img/update_2/shape/cherry_1.png";
import shape_5 from "../assets/img/update_2/shape/shape_5.png";
import team_2_1 from "../assets/img/update_2/team/team_2_1.jpg";
import team_2_2 from "../assets/img/update_2/team/team_2_2.jpg";
import team_2_3 from "../assets/img/update_2/team/team_2_3.jpg";
import team_2_4 from "../assets/img/update_2/team/team_2_4.jpg";
import team_2_5 from "../assets/img/update_2/team/team_2_5.jpg";
import team_2_6 from "../assets/img/update_2/team/team_2_6.jpg";
import team_2_7 from "../assets/img/update_2/team/team_2_7.jpg";
import team_2_8 from "../assets/img/update_2/team/team_2_8.jpg";
import testi_4_1 from "../assets/img/update_2/normal/testi_4_1.jpg";
import quote_1 from "../assets/img/update_2/icon/quote_1.svg";
import testi_4_2 from "../assets/img/update_2/normal/testi_4_2.jpg";
import testi_4_3 from "../assets/img/update_2/normal/testi_4_3.jpg";
import contact_feature_2 from "../assets/img/update_2/icon/contact_feature_2.svg";
import contractfarming from "../assets/img/update_2/icon/contractfarming.png";
import contractfarm01 from "../assets/img/update_2/icon/contractfarm01.jpg";
import qualitypricing from "../assets/img/update_2/icon/qualitypricing.png";
import consistent01 from "../assets/img/update_2/icon/consistent01.jpg";

import quickturnaroundtime from "../assets/img/update_2/icon/Quick Turnaround time.png";
import quick01 from "../assets/img/update_2/icon/quick01.jpg";

import longshelf from "../assets/img/update_2/icon/long shelf.png";
import longshelf01 from "../assets/img/update_2/icon/longshelf01.jpg";
import makeinindia from "../assets/img/update_2/icon/Make in india .png";
import tiger01 from "../assets/img/update_2/icon/tiger01.jpg";
import flexibilitycustomize from "../assets/img/update_2/icon/Flexibility to customize.png";
import flexibility01 from "../assets/img/update_2/icon/flexibility01.jpg";
import contact_feature_3 from "../assets/img/update_2/icon/contact_feature_3.svg";
import testicardimg from "../assets/img/update_2/normal/testi-card_img.png";
import breadedshrimptorpedo1 from "../assets/img/update_2/normal/breadedshrimptorpedo1.jpg";
import qualitydrivenimg1 from "../assets/img/update_2/normal/qualitydrivenimg1.png";
import qualitydrivenupdated1 from "../assets/img/update_2/normal/qualitydrivenupdated1.jpg";
import mission1 from "../assets/img/update_2/normal/mission1.png";
import vision1 from "../assets/img/update_2/normal/vision1.png";
import customerfocuedupdated1 from "../assets/img/update_2/normal/customerfocusedupdated1.jpg";
import td11 from "../assets/img/update_2/normal/td11.jpg";
import backwardintegration1 from "../assets/img/update_2/normal/backwardintegration1.png";
// assets/img/update_2/normal/testi-card_shape_1.png

import testicardshape from "../assets/img/update_2/normal/testi-card_shape_1.png";
import testicardshapeimg from "../assets/img/update_2/normal/testi-card_shape_2.png";
import potatomovingimg1 from "../assets/img/update_2/normal/potatomovingimg1.jpg";
import bgfriesupdated from "../assets/img/update_2/normal/bgfriesupdated.jpg";

import potato1 from "../assets/img/update_2/normal/potato1.png";
import potato2 from "../assets/img/update_2/normal/potato2.png";
import potato3 from "../assets/img/update_2/normal/potato3.png";
import potato4 from "../assets/img/update_2/normal/potato4.png";
import potato5 from "../assets/img/update_2/normal/potato5.png";
import potato6 from "../assets/img/update_2/normal/potato6.png";
import potato7 from "../assets/img/update_2/normal/potato7.png";
import potato8 from "../assets/img/update_2/normal/potato8.png";
import potato9 from "../assets/img/update_2/normal/potato9.png";
import potato10 from "../assets/img/update_2/normal/potato10.png";


const Footer = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div>
            <footer className="footer-wrapper footer-layout5" style={{ backgroundImage: `url(${feature_bg_51})`, marginTop: "10px" }}>
                <div className="widget-area" style={{ backgroundColor: 'white', borderLeft: '100px solid #0f1138', borderRight: '88px solid #0f1138' }}>
                    <div className="container" style={{ backgroundColor: '#fff', paddingTop: '40px', paddingBottom: '20px', marginBottom: '-75px' }}>
                        <div className="row" style={{ marginBottom: '-20px', marginTop: '-175px' }}>
                            <div className="col-md-6">
                                <div className="box-icon-4 text-center" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', marginBottom: '30px', transition: 'background-color 0.3s, color 0.3s', background: '#eff3f6', color: 'black' }}>
                                    <div className="body-content hoveringeffect" style={{ padding: '10px 0' }}>
                                        <div className="heading" style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', fontFamily: 'helvetica', color: '#0f1138' }}>CORPORATE & REGISTERED OFFICE</div>
                                        <p style={{ fontSize: '14px', color: '#212529ed', fontFamily: 'helvetica' }}>4 Basant Lok, Third Floor, Priya Market Vasant Vihar, New Delhi-110057</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box-icon-4 text-center" style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '20px', marginBottom: '30px', transition: 'background-color 0.3s, color 0.3s', background: '#eff3f6', color: 'black' }}>
                                    <div className="body-content hoveringeffect" style={{ padding: '10px 0' }}>
                                        <div className="heading" style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', fontFamily: 'helvetica', color: '#0f1138' }}>FACTORY ADDRESS</div>
                                        <p style={{ fontSize: '14px', color: '#212529ed', fontFamily: 'helvetica' }}>C-5-8, Maroli Udhyog Nagar, Nadod, Umbhrat Road, Navsari, Gujarat-396436</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-4">
                                <div className="box-icon-4 text-center" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', marginBottom: '30px', transition: 'background-color 0.3s, color 0.3s', background: '#eff3f6', color: 'black' }}>
                                    <div className="body-content hoveringeffect" style={{ padding: '10px 0' }}>
                                        <div className="heading" style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>REGISTERED OFFICE</div>
                                        <p style={{ fontSize: '14px', color: '#212529ed' }}>4 Basant Lok, Third Floor, Priya Market Vasant Vihar, New Delhi-110057</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="widget-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-xl-6">
                                <div className="widget footer-widget">
                                    <h3 className="widget_title">
                                        <div className="menu-area">
                                            <div className="container">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-auto">
                                                        <div className="header-logoxxx">
                                                            <a href="">
                                                                <img src={finalLogo} alt="Pizzan" style={{height: '60px',marginBottom:'50px'}}/>
                                                            </a>
                                                        </div>
                                                        
                                                        


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </h3>
                                    <div className="th-widget-about">
                                        <p className="about-text" style={{ fontFamily: 'helvetica', }}>Icedream Global stands out as a top global exporter of frozen food, specializing in processing and packaging potatoes and shrimps. With dual factories in Surat, Gujarat, we lead in technological innovation. Our expert team guarantees superior quality, production, packaging, and export standards.</p>
                                        <div className="th-social">
                                            <a href="https://www.facebook.com/share/yhbpDtnYReppzRBs/?mibextid=qi2Omg" style={{ background: '#F0B323' }}><i className="fab fa-facebook-f"></i></a>
                                            <a href="https://www.linkedin.com/company/icedream-global/" style={{ background: '#F0B323' }}><i className="fab fa-linkedin-in"></i></a>
                                            <a href="https://www.instagram.com/icedreamglobal?igsh=bjU1am1mdTZ3bHNt" style={{ background: '#F0B323' }}><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-xl-auto">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title" style={{ fontFamily: 'helvetica' }}>Useful Links</h3>
                                    <div className="menu-all-pages-container" >
                                        <ul className="menu" style={{ fontFamily: 'helvetica' }}>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/infrastructure" >Infrastructure</Link></li>
                                            <li><Link to="/product">Products</Link></li>
                                            <li><Link to="/quality">Quality</Link></li>
                                            <li><Link to="/contactus">Contact Us</Link></li>
                                            <li><Link to="/careers">Careers</Link></li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget footer-widget">
                                    <h3 className="widget_title" style={{ fontFamily: 'helvetica' }}>Our Location</h3>
                                    <div className="recent-post-wrap">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.0801193980747!2d72.85354431424533!3d21.02947999313118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0571ccb4b8867%3A0x7ec244669086e859!2sHN%20Indigos%20Private%20Limited!5e0!3m2!1sen!2sin!4v1626090743148!5m2!1sen!2sin" width="100%" height="200" style={{ border: '0' }} allowfullscreen="" loading="lazy"></iframe>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6" style={{ fontFamily: 'helvetica' }}>
                                <p className="copyright-text" style={{ fontFamily: 'helvetica' }}> <i className="fal fa-copyright "></i>
                                    2024 Icedream Global by&nbsp;
                                    <a href="https://www.veravalonline.com" target="_blank" style={{ fontFamily: 'helvetica' }}>Veravalonline Pvt. Ltd.</a>&nbsp;-&nbsp;All Rights Reserved
                                </p>
                            </div>
                            <div className="col-lg-6 text-end d-none d-lg-block">
                                <div className="footer-links">
                                    <ul>
                                        <li><a href="about.html" style={{ fontFamily: 'helvetica' }}>Privacy Policy</a></li>
                                        <li><a href="about.html" style={{ fontFamily: 'helvetica' }}>Terms & Condition</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>


    );
};

export default Footer;
