// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from "../assests/img/logo-white.png";


// const Header = () => {
//     return (

//         <header class="th-header header-layout5">



//             <div class="sticky-wrapper">
//                 <div class="menu-area">
//                 <button type="button" class="th-menu-toggle d-inline-block d-lg-none"><i class="far fa-bars"></i></button>
//                     <div class="container">
//                         <div class="row align-items-center justify-content-between">
//                             <div class="col-auto">

//                                 <div class="">
//                                     <Link href="index.html">
//                                         <img img src={logo} alt="logo" />

//                                     </Link>

//                                 </div>
//                             </div>
//                             <div class="col-auto me-xl-auto">
//                                 <nav class="main-menu d-none d-lg-inline-block">

//                                     <ul>

//                                         <li>

//                                             <Link to="/Idgnew/home">Home</Link>
//                                         </li>
//                                         <li>

//                                             <Link to="/Idgnew/about">About Us</Link>

//                                         </li>
//                                         <li>

//                                             <Link to="/Idgnew/infrastructure">Infrastructure</Link>
//                                         </li>

//                                         <li class="menu-item-has-children">

//                                             <Link to="/Idgnew/product">Products</Link>
//                                             <ul class="sub-menu">
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>

//                                             </ul>
//                                         </li>
//                                         <li>

//                                             <Link to="/Idgnew/quality">Quality</Link>

//                                         </li>
//                                         <li>

//                                             <Link to="/Idgnew/contactus">Contact Us</Link>
//                                         </li>


//                                     </ul>
//                                 </nav>
//                             </div>

//                             <div class="col-auto">
//                                 <div class="header-button">
//                                     <div class="header-info">
//                                     <button type="button" class="th-menu-toggle d-inline-block d-lg-none"><i class="far fa-bars"></i></button>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>




//                 </div>



//             </div>
















//         </header>




//     );
// };

// export default Header;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo from "../assests/img/logo-white.png";

// const Header = () => {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsMenuOpen(!isMenuOpen);
//     };

//     return (
//         <header className="th-header header-layout5">
//             <div className="sticky-wrapper">
//                 <div className="menu-area">
//                     {/* <button type="button" className="" onClick={toggleMenu}>
//                         <i className="far fa-bars"></i>
//                     </button> */}
//                     <div className="container">
//                         <div className="row align-items-center justify-content-between">
//                             <div className="col-auto">
//                                 <div className="">
//                                     <Link to="/">
//                                         <img src={logo} alt="logo" />
//                                     </Link>
//                                 </div>
//                             </div>
//                             <div className="col-auto me-xl-auto">
//                                 <nav className={`main-menu ${isMenuOpen ? 'show' : ''}`}>
//                                     <ul style={{marginLeft: '20px' }}>
//                                         <li><Link to="/Idgnew/home">Home</Link></li>
//                                         <li><Link to="/Idgnew/about">About Us</Link></li>
//                                         <li><Link to="/Idgnew/infrastructure">Infrastructure</Link></li>
//                                         <li className="menu-item-has-children">
//                                             <Link to="/Idgnew/product">Products</Link>
//                                             <ul className="sub-menu">
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                             </ul>
//                                         </li>
//                                         <li><Link to="/Idgnew/quality">Quality</Link></li>
//                                         <li><Link to="/Idgnew/contactus">Contact Us</Link></li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                             {/* <div className="col-auto">
//                                 <div className="header-button">
//                                     <div className="header-info">
//                                         <button type="button" className="th-menu-toggle d-inline-block d-lg-none" onClick={toggleMenu}>
//                                             <i className="far fa-bars"></i>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </header>
//     );
// };

// export default Header;





// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import logo from "../assests/img/logo-white.png";

// const Header = () => {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 992);
//         };
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const toggleMenu = () => {
//         setIsMenuOpen(!isMenuOpen);
//     };

//     return (
//         <header className="th-header header-layout5">
//             <div className="sticky-wrapper">
//                 <div className="menu-area">
//                     {/* <button type="button" className="" onClick={toggleMenu}>
//                         <i className="far fa-bars"></i>
//                     </button> */}
//                     <div className="container">
//                         <div className="row align-items-center justify-content-between">
//                             <div className="col-auto">
//                                 <div className="">
//                                     <Link to="/">
//                                         <img src={logo} alt="logo" />
//                                     </Link>
//                                 </div>
//                             </div>
//                             <div className="col-auto me-xl-auto">
//                                 <nav className={`main-menu ${isMenuOpen ? 'show' : ''}`} style={isMobile ? { marginLeft: '20px' } : {}}>
//                                     <ul style={isMobile ? { display: 'flex', marginLeft: '20px' } : {}}>
//                                         <li><Link to="/Idgnew/home">Home</Link></li>
//                                         <li><Link to="/Idgnew/about">About Us</Link></li>
//                                         <li><Link to="/Idgnew/infrastructure">Infrastructure</Link></li>
//                                         <li className="menu-item-has-children">
//                                             <Link to="/Idgnew/product">Products</Link>
//                                             <ul className="sub-menu">
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                                 <li><b>Frozen pud Shrimp</b></li>
//                                             </ul>
//                                         </li>
//                                         <li><Link to="/Idgnew/quality">Quality</Link></li>
//                                         <li><Link to="/Idgnew/contactus">Contact Us</Link></li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                             {/* <div className="col-auto">
//                                 <div className="header-button">
//                                     <div className="header-info">
//                                         <button type="button" className="th-menu-toggle d-inline-block d-lg-none" onClick={toggleMenu}>
//                                             <i className="far fa-bars"></i>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </header>
//     );
// };

// export default Header;

// import React from 'react';
import React, { useState } from 'react';

import logo from "../assests/img/logo-white.png";
import logoidg from "../assets/img/logoidg.png";
import updatedlogo from "../assets/img/updatedlogo.png";
import finalLogo from "../assets/img/finalLogo.png";
import { Link } from 'react-router-dom';

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <nav className="navbar navbar-expand-md navbar-dark sticky-top" style={{background:"#0f1138"}}>
            <div className="container">
                <a className="navbar-brand" href="/#">
                    <img src={finalLogo} alt="finalLogo" style={{ width: '150%', height: '60px',position:"relative",top:"-6px" }} />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse headerlist" id="navbarNav" style={{ fontFamily:'helvetica' }}>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-normal" to="/home">
                                <span className='effecthover'>HOME</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-normal" to="/about">
                                <span className='effecthover'>ABOUT US</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link text-white fw-normal" to="/infrastructure">
                                <span className='effecthover'>INFRASTRUCTURE</span>
                                <ul className="sub-menuEffect" style={{ fontFamily:'helvetica' }}>
                                    <li className='hovering'><Link to="/shrimpsprocessing"><b>SHRIMPS PROCESSING</b></Link></li>
                                    <li className='hovering' style={{ fontFamily:'helvetica' }}><Link to="/potatoprocessing"><b>POTATO PROCESSING</b></Link></li>
                                    {/* <li className='hovering helvetica'><b><Link to="/potatoprocessing"><b>POTATO PROCESSING</b></Link></b></li> */}
                                </ul>
                            </Link>
                        </li>



                        <li className="nav-item" style={{ fontFamily:'helvetica' }}>
                            <Link className="nav-link text-white fw-normal" to="/product">
                                <span className='effecthover'>PRODUCTS</span>
                                <ul className="sub-menuEffect">
                                    <li className='hovering'>
                                        <Link to="/frozenshrimp"><b> FROZEN SHRIMPS</b></Link>
                                      
                                    </li>
                                   
                                    <li className='hovering'>
                                        <Link to="/frozenpotatoes"><b>FROZEN POTATOES</b></Link>
                                    </li>
                                    <li className='hovering' onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                        <Link to="/frenchfries"><b>VALUE ADDED SNACKS</b></Link>
                                        {showDropdown && (
                                            <ul className="sub-menuEffect-child">
                                                <li><Link to="/shrimps">SHRIMPS</Link></li>
                                                <li><Link to="/potatoes">POTATOES</Link></li>
                                            </ul>
                                        )}
                                    </li>

                                </ul>
                            </Link>
                        </li>


                        <li className="nav-item" style={{ fontFamily:'helvetica' }}>
                            <Link className="nav-link text-white fw-normal" to="/quality">
                                <span className='effecthover'>QUALITY</span>
                            </Link>
                        </li>

                        <li className="nav-item" style={{ fontFamily:'helvetica' }}>
                            <Link className="nav-link text-white fw-normal" to="/contactus">
                                <span className='effecthover'>CONTACT US</span>
                            </Link>
                        </li>
                        <li className="nav-item" style={{ fontFamily:'helvetica' }}>
                            <Link className="nav-link text-white fw-normal" to="/careers">
                                <span className='effecthover'>CAREERS</span>
                            </Link>
                        </li>
                        <li className="nav-item" style={{ fontFamily:'helvetica' }}>
                            <Link className="nav-link text-white fw-normal" to="/blog">
                                <span className='effecthover' style={{ fontFamily:'helvetica' }}>BLOG</span>
                            </Link>
                        </li>
     
                    </ul>
                </div>

            </div>
            
        </nav>
    );
};

export default Header;




