// import React from 'react';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route,Routes ,useLocation} from 'react-router-dom';
import Header from './pages/header';
import About from './pages/about';
// import Homepage from './pages/homepage';
 
import Infrastructure from './pages/infrastructure';
import Product from './pages/product';
import Quality from './pages/quality';
import Contactus from './pages/contactus';
import Shrimpsprocessing from './pages/shrimpsprocessing';
import Potatoprocessing from './pages/potatoprocessing';
import Frozenshrimp from './pages/frozenshrimp';
import Frenchfries from './pages/frenchfries';
import Frozenpotatoes from './pages/frozenpotatoes';
import Shrimps from './pages/shrimps';
import Potatoes from './pages/potatoes';
import Careers from './pages/careers';
import Seniorsalesexecutive from './pages/seniorsalesexecutive';
import Areasalesmanager from './pages/areasalesmanager';
import Seniorhrmanager from './pages/seniorhrmanager';
import Salesexecutive from './pages/salesexecutive';
import Blog from './pages/blog';
import Homepage from './pages/homepage';






const App = () => {
 
  return (
    <Router>
       <ScrollToTop />
      <div>
     
        <Header />
        <ConditionalHomepage/>
        <Routes>
          
         
       
          {/* <Route path="/" element={<ConditionalHomepage />} /> */}
          {/* <Route path="/Idgnew/home" element={<ConditionalHomepage />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/infrastructure" element={<Infrastructure/>} />
          <Route path="/shrimpsprocessing" element={<Shrimpsprocessing/>} />
          <Route path="/potatoprocessing" element={<Potatoprocessing/>} />
          <Route path="/frozenpotatoes" element={<Frozenpotatoes/>} />
          <Route path="/frozenshrimp" element={<Frozenshrimp/>} />
          <Route path="/shrimps" element={<Shrimps />} />
          <Route path="/potatoes" element={<Potatoes />} />
          <Route path="/frenchfries" element={<Frenchfries/>} />
          <Route path="/product" element={<Product />} />
          <Route path="/quality" element={<Quality />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/jobs/senior-sales-executive" element={<Seniorsalesexecutive />} />
          <Route path="/jobs/area-sales-manager" element={<Areasalesmanager />} />
          <Route path="/jobs/senior-human-resource-manager" element={<Seniorhrmanager />} />
          <Route path="/jobs/sales-executive" element={<Salesexecutive />} />
          
          

      
         
        </Routes>
      </div>
    </Router>
  );
};

const ConditionalHomepage = () => {
  const location = useLocation();
  if (!['/about', '/infrastructure', '/product', '/quality', '/contactus','/shrimpsprocessing','/potatoprocessing','/frozenshrimp','/frenchfries','/frozenpotatoes','/shrimps','/potatoes','/careers','/jobs/senior-sales-executive','/jobs/area-sales-manager','/jobs/senior-human-resource-manager','/jobs/sales-executive','/blog'].includes(location.pathname)) {
    return <Homepage  />;
  }

  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
