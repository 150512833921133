// import React from 'react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import hero_bg_7 from "../assets/img/update_2/hero/hero_bg_7_1_1.jpg";
import certifications6 from "../assets/img/certifications6.jpg";
import certifications4 from "../assets/img/certifications4.jpg";
import category_6_2 from "../assets/img/update_2/normal/category_6_2.png";
import category_6_3 from "../assets/img/update_2/normal/category_6_3.png";
import category_6_4 from "../assets/img/update_2/normal/category_6_4.png";
import category_6_5 from "../assets/img/update_2/normal/category_6_5.png";
import category_6_7 from "../assets/img/update_2/normal/category_6_7.png";
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/slick.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './footer';
const Shrimpsprocessing = () => {

    // const settings = {
    //     // dots: true,
    //     // infinite: true,
    //     // speed: 500,
    //     // slidesToShow: 4,
    //     // slidesToScroll: 1


    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,       
    //     autoplaySpeed: 2000 
    // };
    const certifications = [
        { name: 'HACCP-Compliant Processing Facility', number: '1' },
        { name: 'ISO 22000 Certified', number: '2' },
        { name: 'BRC Certified', number: '3' },
        { name: 'BAP Certified', number: '4' },
        { name: 'FDA-Approved \'A\' list packer', number: '5' },
        { name: 'EU-Approved Unit', number: '6' },
        { name: 'EIA-Approved Lab Technologists', number: '7' },
        { name: 'Sophisticated lab facility', number: '8' },
        { name: 'Standardised grading & weighment facility', number: '9' }
    ];
    const settingstwice = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500, // Change this value for faster or slower speed


        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settingsscroll = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <></>,
        nextArrow: <></>,

        appendDots: dots => (
            <ul style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                {dots.map((dot, index) => (
                    <li key={index} style={{ listStyle: 'none', margin: '0 10px' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: index === dot.props.current ? '#ff0000' : '#fff', // Red for active dot, white for others
                                border: '0px solid #333', // Customize the border color
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        />
                    </li>
                ))}
            </ul>
        )

    };

    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const galleryImages = [
        { id: 1, src: 'assets/img/update_2/gallery/gallery_4_1.jpg' },
        { id: 2, src: 'assets/img/update_2/gallery/gallery_4_2.jpg' },
        { id: 3, src: 'assets/img/update_2/gallery/gallery_4_3.jpg' },
        { id: 4, src: 'assets/img/update_2/gallery/gallery_4_5.jpg' },

    ];


    return (
        <div>
            {/* <div class="d-none d-md-block">
        <div id="slider" class="ls-wp-container fitvidsignore hero-7 th-hero-carousel"
            style="width:1920px;height:900px;margin:0 auto;">
            <div class="ls-slide"
                data-ls="bgsize:cover; bgposition:50% 50%; duration:5000; transition2d:11; kenburnszoom:in; kenburnsscale:1.2;">
                <img width="1920" height="900" src="assets/img/update_2/hero/hero_bg_7_1_1.jpg" class="ls-bg" alt=""
                    decoding="async"/>
                <ls-layer
                    style="font-size:36px; color:#000; stroke:#000; stroke-width:0px; text-align:left; font-style:normal; text-decoration:none; text-transform:none; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; width:1920px; height:900px; -webkit-background-clip:border-box;"
                    class="ls-l ls-svg-layer" data-ls="static:forever;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1920 900" fill="none">
                        <rect width="1920" height="900" fill="url(#paint0_radial_15_366)" fill-opacity="0.85"></rect>
                        <defs>
                            <radialgradient id="paint0_radial_15_366" cx="0" cy="0" r="1" gradientunits="userSpaceOnUse"
                                gradienttransform="translate(960 490) rotate(90) scale(410 874.667)">
                                <stop stop-opacity="0.5" offset="0"></stop>
                                <stop offset="1"></stop>
                            </radialgradient>
                        </defs>
                    </svg>
                </ls-layer>
                <ls-layer
                    style="font-size:26px; stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:311px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-tablet ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special sea food exporter
                </ls-layer>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; top:350px; left:50%; font-size:120px; font-weight:700; color:#ffffff; line-height:130px; white-space:normal;"
                    class="ls-l ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                     SEA FOOD  
                </h1>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; font-weight:700; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; line-height:130px; top:478px; left:50%; font-size:120px; color:#ffffff; white-space:normal;"
                    class="ls-l hero-title ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:400; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    EXPORTER
                </h1>
                <a style="" class="ls-l ls-hide-tablet ls-hide-phone" href="shop.html" target="_self"
                    data-ls="offsetyin:-300; durationin:1200; delayin:500; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#ff0600;">
                    <ls-layer
                        style="font-size:14px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; width:180px; left:50%; top:647px; text-transform:uppercase; line-height:14px; padding-bottom:20px; padding-top:22px; font-weight:600; border-radius:4px 4px 4px 4px; background-color:#ff0600;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
                <ls-layer
                    style="stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:300px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-desktop ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special Fast food restaurant
                </ls-layer>
                <a style="" class="ls-l ls-hide-desktop" href="shop.html" target="_self"
                    data-ls="offsetxin:500; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#eb0029;">
                    <ls-layer
                        style="font-size:32px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; left:50%; top:642px; text-transform:uppercase; padding-bottom:30px; padding-top:30px; font-weight:600; line-height:32px; background-color:#eb0029; border-radius:10px 10px 10px 10px; width:370px;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
            </div>
           
            <div class="ls-slide"
                data-ls="bgsize:cover; bgposition:50% 50%; duration:5000; transition2d:11; kenburnszoom:in; kenburnsscale:1.2;">
                <img width="1920" height="900" src="assets/img/update_2/hero/hero_bg_7_1_2.jpg" class="ls-bg" alt=""
                    decoding="async"/>
                <ls-layer
                    style="font-size:26px; stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:311px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-tablet ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special sea food exporter
                </ls-layer>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; top:350px; left:50%; font-size:120px; font-weight:700; color:#ffffff; line-height:130px; white-space:normal;"
                    class="ls-l ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
             SEA FOOD
                </h1>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; font-weight:700; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; line-height:130px; top:478px; left:50%; font-size:120px; color:#ffffff; white-space:normal;"
                    class="ls-l hero-title ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:400; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    EXPORTER
                </h1>
                <a style="" class="ls-l ls-hide-tablet ls-hide-phone" href="shop.html" target="_self"
                    data-ls="offsetyin:-300; durationin:1200; delayin:500; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#ff0600;">
                    <ls-layer
                        style="font-size:14px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; width:180px; left:50%; top:647px; text-transform:uppercase; line-height:14px; padding-bottom:20px; padding-top:22px; font-weight:600; border-radius:4px 4px 4px 4px; background-color:#ff0600;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
                <ls-layer
                    style="stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:300px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-desktop ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special sea food exporter
                </ls-layer>
                <a style="" class="ls-l ls-hide-desktop" href="shop.html" target="_self"
                    data-ls="offsetxin:500; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#eb0029;">
                    <ls-layer
                        style="font-size:32px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; left:50%; top:642px; text-transform:uppercase; padding-bottom:30px; padding-top:30px; font-weight:600; line-height:32px; background-color:#eb0029; border-radius:10px 10px 10px 10px; width:370px;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
            </div>
        
            <div class="ls-slide"
                data-ls="bgsize:cover; bgposition:50% 50%; duration:5000; transition2d:11; kenburnszoom:in; kenburnsscale:1.2;">
                <img width="1920" height="900" src="assets/img/update_2/hero/hero_bg_7_1_3.jpg" class="ls-bg" alt=""
                    decoding="async"/>
                <ls-layer
                    style="font-size:26px; stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:311px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-tablet ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special sea food exporter
                </ls-layer>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; top:350px; left:50%; font-size:120px; font-weight:700; color:#ffffff; line-height:130px; white-space:normal;"
                    class="ls-l ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                   SEA FOOD
                </h1>
                <h1 style="text-align:left; text-decoration:none; text-transform:uppercase; font-weight:700; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; font-family:Rubik; line-height:130px; top:478px; left:50%; font-size:120px; color:#ffffff; white-space:normal;"
                    class="ls-l hero-title ls-hide-phone ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:400; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    EXPORTER
                </h1>
                <a style="" class="ls-l ls-hide-tablet ls-hide-phone" href="shop.html" target="_self"
                    data-ls="offsetyin:-300; durationin:1200; delayin:500; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#ff0600;">
                    <ls-layer
                        style="font-size:14px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; width:180px; left:50%; top:647px; text-transform:uppercase; line-height:14px; padding-bottom:20px; padding-top:22px; font-weight:600; border-radius:4px 4px 4px 4px; background-color:#ff0600;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
                <ls-layer
                    style="stroke:#000; stroke-width:0px; text-align:center; font-style:normal; text-decoration:none; text-transform:capitalize; font-weight:400; letter-spacing:0px; background-position:0% 0%; background-repeat:no-repeat; background-clip:border-box; overflow:visible; color:#ffffff; font-family:'Lobster Two'; top:300px; left:50%;  -webkit-background-clip:border-box;"
                    class="ls-l ls-hide-desktop ls-text-layer"
                    data-ls="offsetyin:-300; durationin:1200; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-200; durationout:400; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent;">
                    Special sea food exporter
                </ls-layer>
                <a style="" class="ls-l ls-hide-desktop" href="shop.html" target="_self"
                    data-ls="offsetxin:500; delayin:200; easingin:easeOutBack; bgcolorin:transparent; colorin:transparent; offsetxout:-120; durationout:800; startatout:slidechangeonly + ; bgcolorout:transparent; colorout:transparent; hover:true; hoveropacity:1; hoverbgcolor:#ffffff; hovercolor:#eb0029;">
                    <ls-layer
                        style="font-size:32px; color:#fff; text-align:center; font-family:Rubik; cursor:pointer; left:50%; top:642px; text-transform:uppercase; padding-bottom:30px; padding-top:30px; font-weight:600; line-height:32px; background-color:#eb0029; border-radius:10px 10px 10px 10px; width:370px;"
                        class="ls-ib-icon ls-button-layer">
                        Explore menu<i class="fa fa-arrow-right" style="margin-left:.5em; font-size:1em;"></i>
                    </ls-layer>
                </a>
            </div>
        </div>
    </div> */}
            <div classNameName="d-none d-md-block">
                <div id="slider" classNameName="ls-wp-container fitvidsignore hero-6 th-hero-carousel"
                    style={{ margin: '0 auto' }}>
                    <div classNameName="ls-slide" data-ls="duration:5000; kenburnsscale:1.2;">
                        {/* <div className="infrastructure-name">
                            <div style={{marginTop:"-40px"}}>
                            <ul >
                            <li><h2 style={{color:'white'}}>Infrastructure</h2></li>
                                <li><Link to="/Idgnew/home">Home</Link></li>
                               
                            </ul>
                            </div>
                        </div> */}
                        <div className="infrastructure-name">
                            <div style={{ marginTop: "-40px" }}>
                                <ul style={{ listStyleType: 'none', padding: 20 }}>
                                    <li><h1 style={{ color: 'white', margin: 0, padding: 0 }} style={{ fontFamily:'helvetica' ,color:'white'}}>INFRASTRUCTURE</h1></li>
                                    <li>
                                        <Link to="/home" style={{ color: 'white', padding: 4 }}><span className="homeclass" style={{ fontFamily:'helvetica' }}>Home</span> &nbsp;</Link>
                                        <span style={{ color: 'white' }}>&gt; &nbsp;<span className="redname" style={{ fontFamily:'helvetica',color:'#F0B323' }}>Infrastructure</span></span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* <img width="2000" height="400" src={hero_bg_7} classNameName="ls-l ls-img-layer" alt="image"
                            decoding="async"
                            style={{ fontSize: '36px', color: '#000', stroke: '#000', strokeWidth: '0px', textAlign: 'right', fontStyle: 'normal', textDecoration: 'none', textTransform: 'none', fontWeight: '400', letterSpacing: '0px', backgroundPosition: '0% 0%', backgroundRepeat: 'no-repeat', backgroundClip: 'border-box', overflow: 'visible', left: '0px', top: '107px', WebkitBackgroundClip: 'border-box' }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;" /> */}

                        <img
                            width="2000"
                            height="435"
                            src={hero_bg_7}
                            className="ls-l ls-img-layer factory"
                            alt="image"
                            decoding="async"
                            style={{
                                fontSize: '36px',
                                height: "490px",
                                color: '#000',
                                stroke: '#000',
                                strokeWidth: '0px',
                                textAlign: 'right',
                                fontStyle: 'normal',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontWeight: '400',
                                letterSpacing: '0px',
                                backgroundPosition: '0% 0%',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'border-box',
                                overflow: 'visible',
                                left: '0px',
                                top: '107px',
                                WebkitBackgroundClip: 'border-box'
                            }}
                            data-ls="offsetxin:500; durationin:600; delayin:100; easingin:easeOutBack; rotatein:80; bgcolorin:transparent; colorin:transparent;"
                        />
                    </div>
                </div>
            </div><br /><br />
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{ border: '1px solid #ddd', padding: '20px', marginBottom: '20px', background: 'antiquewhite', boxShadow: '0 4px 15px rgba(0,0,0,0.1)' }}>
                        <div className="about-sec overflow-hidden pb-5">
                            {/* <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>SHRIMPS PROCESSING</h2> */}
                            <div class="title-area mb-30  text-center">
                    <h1 className="section-heading why-feature_title titlename" style={{ fontFamily:'helvetica' }}>SHRIMPS PROCESSING</h1>
                </div>
                            <p className="section-subheading">
                                <p style={{ fontFamily:'helvetica' }}>
                                Our processing plant, strategically located near shrimp aquaculture, ensures optimal cold chain effectiveness within six kilometers of the farming site. With EU approval, full HACCP compliance, and a meticulously planned layout, we maintain the highest quality standards and eliminate cross-contamination risks. Our state-of-the-art GLC-based refrigeration system ensures rapid freezing and stable storage temperatures, reflecting our dedication to modernity and quality assurance.
                            </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <h2 className="section-heading">
                SHRIMPS PROCESSING
            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                            Nestled amidst the picturesque landscape of shrimp aquaculture, IDG's state-of-the-art processing plant stands as a testament to our commitment to excellence, ensuring the highest quality standards for our discerning customers.
                            </p>
                            </div>
                    </div>
                </div>
            </div> */}

            {/* <h2 className="section-heading">
                POTATO PROCESSING
            </h2>
            <div class="about-sec overflow-hidden pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <p class="section-subheading">
                            Nestled amidst fertile fields, IDG's potato processing plant is a beacon of excellence, transforming humble potatoes into premium products with unmatched quality. Our plant combines traditional craftsmanship with modern technology to deliver products that delight customers around the world.
                            </p>
                            </div>
                    </div>
                </div>
            </div> */}

            {/* <h2 className="section-heading" style={{ fontFamily:'helvetica' }}>
                Different types of freezing
            </h2> */}
             <div class="title-area mb-30  text-center">
                    <h1 className="section-heading why-feature_title titlename" style={{ fontFamily:'helvetica' }}>Different types of freezing</h1>
                </div>
            <div class="about-sec overflow-hidden pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            {/* <p class="section-subheading">
                                The processing plant is strategically situated at the heart of shrimp aquaculture, mere kilometers away from the nearest farming site. This prime location enables us to attain unparalleled cold chain effectiveness, ensuring the maintenance of the utmost quality standards for our shrimp products.
                            </p>
                            <p class="section-subheading">
                                Our state-of-the-art facility, recently constructed and fully operational, boasts EU approval and complete HACCP compliance. Meticulous planning of the plant layout guarantees a seamless, linear material flow, eliminating any possibility of cross-contamination of our premium finished products. What's more, our cutting-edge GLC-based refrigeration system, designed with modernity in mind, offers swift freezing capabilities and maintains stable storage temperatures, ensuring the preservation of freshness and quality at every step.
                            </p> */}
                            <p>
                                <ul class="list-group" style={{ fontFamily:'helvetica' }}>
                                    <li class="list-group-item"><span class="dot"></span>Captive Pre-processing Facility (with over 450 skilled labor workforce)
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Plate Freezing System
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Blast Freezing Unit
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Individually Quick Freezing (IQF) Equipment
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Captive Tube and Crushed Ice Plants
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Large Cold Storage Facility
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                    <li class="list-group-item"><span class="dot"></span>Captive Effluent Handling Facility
                                        <span class="badge" style={{ backgroundColor: '#F0B323' }}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg></span></li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mt-5">
                <div class="container">
                    {/* <div class="title-area text-center">
                        <span class="sub-title">Best Sea food for family</span>
                        <h2 class="sec-title">Choose Our best Product</h2>
                    </div> */}
                    <div class="row slider-shadow th-carousel" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                        data-sm-slide-show="2" data-xs-slide-show="1" data-arrows="true">
                        <Slider {...settingsscroll}>
                            <div class="col-xl-2 col-lg-4 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <img src={category_6_2} alt="Image" style={{ height: '290px' }} />
                                    </div>
                                    {/* <h3 class="category-border_title"><a href="shop.html">Vegetable</a></h3> */}

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                {/* category-border */}
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <img src={category_6_3} alt="Image" style={{ height: '290px' }} />
                                    </div>
                                    {/* <h3 class="category-border_title"><a href="shop.html">Tandoori</a></h3> */}

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <img src={category_6_4} alt="Image" style={{ height: '290px' }} />
                                    </div>
                                    {/* <h3 class="category-border_title"><a href="shop.html">Sea Foods</a></h3> */}

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">
                                <div class="category-border">
                                    <div class="category-border_img">
                                        <img src={category_6_5} alt="Image" style={{ height: '290px' }} />
                                    </div>
                                    {/* <h3 class="category-border_title"><a href="shop.html">Barbecue</a></h3> */}

                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm2">

                                <div class="category-border">
                                    <div class="category-border_img">
                                        <img src={category_6_7} alt="Image" style={{ height: '290px' }} />
                                    </div>
                                    {/* <h3 class="category-border_title"><a href="shop.html">Fast Foods</a></h3> */}

                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </div>
            {/* <h2 class="section-heading">
                CERTIFICATION
            </h2>
          
            <div className="about-sec overflow-hidden pb-2" style={{ background: 'chocolate', padding: '30px 0' }}>
                
                <div className="container">
                    <div className="row">
                        {certifications.map((certification, index) => (
                            <div key={index} className="col-md-6 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-center align-items-center" style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
                                        <div>
                                            {certification.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            <br /><br />

            {/* <section class="mt-80" data-pos-for="#testiSec" data-sec-pos="bottom-half">
                <div class="container">

                    <div class="title-area text-center">
                        <span class="sub-title">Photo Gallery</span>
                        <h2 class="sec-title">Our Latest Photos Gallery</h2>
                    </div>
                </div>
            </section>
            <div className="row gy-40">
               
                <Slider {...settings}>
                    {galleryImages.map((image, index) => (
                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                            <div className="th-menu">
                                <div className="">
                                    <div className="gallery-card style2 mb-0">
                                        <div className="gallery-img">
                                            <img
                                                style={{ borderRadius: '3%', width: '400px', height: '300px' }}
                                                src={image.src}
                                                alt="gallery image"
                                                onClick={() => openModal(image.src)}
                                            />
                                        </div>
                                        <div className="gallery-content">
                                            <h2 className="gallery-title box-title">Production House</h2>
                                            <button className="icon-btn popup-image" onClick={() => openModal(image.src)}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="View Image"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: '111'
                    },
                    content: {
                        width: '60%', // Set the width of the modal
                        height: '60%', // Set the height of the modal
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        background: '#fff', // Set the background color to white
                        borderRadius: '5px', // Optional: Add border radius for rounded corners
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Optional: Add box shadow for a better look
                    },
                }}
            >
                <img src={selectedImage} alt="Full-screen gallery" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', color: '#000', fontSize: '20px', cursor: 'pointer' }}>Close</button>
            </Modal> */}
            <br /><br />
            <section className="overflow-hidden space" style={{ marginTop: '-50px', padding: '20px' }}>
                <div class="title-area mb-30  text-center">
                    <h1 className="why-feature_title titlename" style={{ fontFamily:'helvetica' }}>OUR CERTIFICATIONS</h1>
                </div>
                <Slider {...settingstwice}>
                    <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
                        <img className="gray" src={certifications6} alt="Brand Logo" />
                    </div>
                    <div style={{ border: '0px solid #eb2e2e', display: 'inline-block', padding: '26px', borderRadius: '10px' }}>
                        <img className="gray" src={certifications4} alt="Brand Logo" />
                    </div>
                </Slider>

            </section>
            <br /><br /><br /><br /><br />
            <Footer />
        </div>
    );
};

export default Shrimpsprocessing;